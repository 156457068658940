<template>
  <div
    v-if="nodeEarnings"
    class="node-proofs-stats"
  >
    <div class="node-proofs-stats__wrapper">
      <div class="node-proofs-stats__col1">
        <div class="sort-by-time">
          <span
            v-for="(item, key, index) in sortList"
            :key="index"
            class="sort-by-time__btn"
            :class="{ 'sort-by-time_btn-active': sortBy === key }"
            @click="sortBy = key"
          >{{ item }}</span>
        </div>
        <InfoCard
          v-for="(card, index) in getInfoCards"
          :key="index"
          :content="card"
        />
      </div>
      <div class="node-proofs-stats__col2">
        <!-- <div class="chart-date-picker">CALENDAR</div> -->
        <div class="node-proofs-stats__chart">
          <LineChart
            :key="getLineChartData.id"
            id="LineChart"
            :background-color="[ 'green', 'red', '#ffd600' ]"
            :border-color="['green', 'red', '#ffd600']"
            :data="getLineChartData.counts"
            :labels="getLineChartData.dates"
            :title="getLineChartData.titles"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/charts/LineChart.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { getStyledTime } from '@/helpers/common'
import InfoCard from './NodeInfoCard.vue'
export default {
  name: 'NodeProofsStats',
  components: { InfoCard, LineChart },
  data () {
    return {
      sortBy: 'total_info',
      sortList: {
        total_info: 'all time',
        last_month: 'm',
        last_week: 'w',
        last_day: 'd'
      }
    }
  },
  computed: {
    ...mapGetters(['convertBalance']),
    ...mapState(['nodeEarnings', 'selectedNodeProofs']),
    getInfoCards () {
      return {
        col1: [
          { title: 'TBY mined:', subtitle: this.convertBalance(this.getNodeEarning.tby_mined) + ' TBY', class: 'fs-32' },
          { title: 'TBY rewarded:', subtitle: this.convertBalance(this.getNodeEarning.system_reward_tby) + ' TBY', class: 'fs-24' },
          { title: 'Network gas token used:', subtitle: this.convertBalance(this.getNodeEarning.fees) + ' MATIC', class: 'fs-24-red' }
        ],
        col2: [
          { title: 'Proofs count:', subtitle: this.getNodeEarning.proofs_count, class: 'fs-32' },
          { title: 'Time from last proof:', subtitle: this.getLastTimeProof, class: 'fs-24' },
          { title: 'Failed proofs count:', subtitle: this.getNodeEarning.failed_count, class: 'fs-24-red' }            
        ]
      }
    },
    getNodeEarning () {
      return this.nodeEarnings[this.sortBy]
    },
    getLastTimeProof () {
      const lastTimeProof = this.getNodeEarning.end_time
      if (lastTimeProof !== 0) {
        const date = new Date(lastTimeProof).getTime()
        const dateToSeconds = getStyledTime((Date.now() / 1000) - (date / 1000))
        return dateToSeconds
      } else {
        return '00:00:00'
      }
    },
    getLineChartData () {
      const nodeProofsDates = this.selectedNodeProofs.map(x => new Date(x.timestamp).setHours(0, 0, 0, 0))
      let failedProofs = this.selectedNodeProofs.filter(x => x.failed).map(x => new Date(x.timestamp).setHours(0, 0, 0, 0))
      const dateCountRepeat = {}
      const failedProofsRepeated = {}
      nodeProofsDates.forEach(el => {
        dateCountRepeat[el] = (dateCountRepeat[el] || 0) + 1
        if (failedProofs.includes(el)) {
          failedProofs.forEach(x => {
            if (el === x) failedProofsRepeated[x] = (failedProofsRepeated[x] || 0) + 1
          })
          failedProofs = failedProofs.filter(x => x !== el)
        } else {
          failedProofsRepeated[el] = (failedProofsRepeated[el] || 0) + 0
        }
      })
      const dates = Object.keys(dateCountRepeat).map(x => new Date(Number(x)).toLocaleDateString())
      const counts = [Object.values(dateCountRepeat), Object.values(failedProofsRepeated)]
      return { counts, dates, id: Date.now(), titles: ['Total proofs count', 'Failed proofs count'] }
    }
  },
  async mounted () {
    const id = this.$route.params.nodeID
    this.getNodeEarningsById(id)
    this.getProofsById(id)
  },
  methods: {
    ...mapActions(['getNodeEarningsById', 'getProofsById'])
  }
}
</script>
<style lang="scss" scoped>
.node-proofs-stats {
  &__wrapper {
    display: flex;
  }
  &__col1 {
    @include flexPercent(20%);
  }
  &__col2 {
    display: flex;
    flex-wrap: wrap;
    @include flexPercent(80%);
    padding: 12px 28px;
  }
  &__chart {
    width: 100%;
    padding: 24px 24px 0 24px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-size: 36px;
    // height: 800px;
    // min-height: 540px;
    // border: 1px solid $colorBlack;
    border: 1px solid $colorGreySecondary8;
    border-radius: 16px;
  }
}
.sort-by-time {
  padding: 12px;
  display: flex;
  &__btn {
    @include flexBox(center, center);
    width: 18%;
    padding: 8px 4px;
    border: 1px solid $colorGreySecondary3;
    border-radius: 8px;
    margin-left: 8px;
    cursor: pointer;
    &:first-child {
      width: 46%;
      margin-left: 0;
    }
  }
  &_btn-active {
    border-color: $colorYellow;
    background-color: $colorYellow;
  }
}

</style>
