<template>
  <div class="gauge-chart">
    <span style="color:red">{{ itemObj.diff }}</span>
    <div class="gauge-chart__title">
      <span>{{ itemObj.title }}</span>
    </div>
    <div :style="{ backgroundColor: itemObj.color }" class="gauge-chart__semi-circle" />
    <div class="gauge-chart__number gauge-chart__count">
      <span>{{ itemObj.count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GaugeChart',
  props: {
    itemObj: { type: Object, default () {} }
  }
}
</script>
<style lang="scss">
.gauge-chart {
  border-radius: 0.5vw;
  margin: 1vw;
  padding: 1vw 1vw 0 1vw;
  background-color: #fff;
  // box-shadow: 0 0.75vw 2.95vw rgb(0 0 0 / 13%);
  &__title{
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    @media (min-width:1921px) {
      font-size: 1.1vw;
    }
  }
  &__number{
    font-size: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    @media (min-width:1921px) {
      font-size: 1.5vw;
    }
  }
  &__count{
    z-index: 111;
    bottom: 35px;
    @media (min-width:1921px) {
      bottom: 1.75vw;
    }
  }
  &__semi-circle {
    background: green;
    position: relative;
    display: block;
    margin-top: 1vw;
    width: 20.5rem;
    height: 10.25rem;
    border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% ;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 2;
      display: block;
      width: 12rem;
      height: 6rem;
      margin-left: -6rem;
      background: #fff;
      border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% ;
      @media (min-width:1921px) {
        width: 9.6vw;
        height: 4.8vw;
        margin-left: -4.8vw;
      }
    }
    @media (min-width: 1921px) {
      margin-top: 1vw;
      width: 16.4vw;
      height: 8.2vw;
    }
  }
}
 </style>
