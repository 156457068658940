import { createStore } from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import Web3 from 'web3'
import { decimalAdjust } from '@/scripts/control'
const bigNumber = require('bignumber.js')
export default createStore({
  state: {
    web3: new Web3(Web3.givenProvider),
    baseUrl: 'https://analytics.denetwork.xyz/api/v1',
    selectedVersion: '',
    updateText: 'Update Node',
    deskStatsInfo:{},
    statsTotalInfo: {},
    nftStatsTotalInfo: {},
    nftStatsInfo: {},
    storageDownloads: {},
    totalUsers: {},
    totalRewards: {},
    totalReferral: {},
    totalUserChat: {},
    firstLessonCount: {},
    secondLessonCount: {},
    thirdLessonCount: {},
    fourthLessonCount: {},
    fivethLessonCount: {},
    totalNftRequest: {},
    examTotalCount: {},
    bountyTasks: {},
    topReferrers: {},
    topEarners: {},
    storageDownloadInfo: {},
    beginTime: '',
    endTime: '',
    login: false,
    clickValue: false,
    nodeLastTotalInfo: [],
    infoNode: [],
    lineColors: ['#32A143','#EE8346','#2053D7','#C248ED'],
    // new state
    url: 'https://statistic-dev.denetwork.xyz',
    dataUsersInfo: {},
    user: {
      token: null,
      address: null
    },
    nodes: [],
    nodesEarningInfo: {},
    isNodesLoading: false,
    nodeProofs: [],
    node: null,
    nodeEarnings: null,
    allNodesEarnings: [],
    selectedNodeProofs: []
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['storageDownloadInfo', 'nodes']
    }),
    createPersistedState({
      storage: window.localStorage,
      paths: ['login', 'user', 'bountyTasks', 'nftStatsInfo', 'selectedVersion', 'nftStatsTotalInfo', 'statsTotalInfo', 'deskStatsInfo', 'storageDownloads', 'firstLessonCount', 'secondLessonCount', 'thirdLessonCount', 'fourthLessonCount', 'fivethLessonCount', 'totalNftRequest', 'examTotalCount']
    })
  ],
  mutations: {
    stateUpdater (state, payload) {
      state[payload.name] = payload.value
    },
    setUserAddress (state, payload) {
      state.user.address = payload
    },
    setUserToken (state, payload) {
      state.user.token = payload
    }
  },
  getters: {
    apiUrl: (state) => (endpoint) => {
      return state.url + '/' + endpoint
    },
    getPreviousNextNodeById: (state) => (id) => {
      const index =  state.nodes.findIndex((a) => a.id === id)
      if (index === -1) {
        console.log(true)
      }
      const prev = state.nodes[index - 1] ? state.nodes[index - 1].id : undefined
      const next = state.nodes[index + 1] ? state.nodes[index + 1].id : undefined
      return { prev, next }
    },
    convertBalance: (state) => (wei, exp = -3) => {
      if (wei !== 0 && !wei) return null
      const numb = bigNumber(wei.toString()).dividedBy(1000).toString().split('.')[0]
      const fromWei = state.web3.utils.fromWei(numb.toString())
      const res = bigNumber(fromWei).times(1000)
      return decimalAdjust(res, 'round', exp)
    },
    convertBalanceInWei: (state) => (wei) => {
      return state.web3.utils.fromWei(wei.toString())
    },
    getStorageInfo: (state) => {
      return state.node ? state.node.resources : null
    },
    toStringHash: (state) => (value) => {
      const encodedData = atob(value)
      let result = ''
      for (let i = 0; i < encodedData.length; i++) {
        const hex = encodedData.charCodeAt(i).toString(16)
        result += (hex.length === 2 ? hex : '0' + hex)
      }
      return '0x' + result
    }
  },
  actions: {
    botTotalUsers ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/tgbot/users_count',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalUsers', value: res.data })
        })
    },
    botUserBalance ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/tgbot/users_balance',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalRewards', value: res.data })
        })
    },
    botUserReferral ({ state, commit}) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/tgbot/referrals',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalReferral', value: res.data })
        })
    },
    telegramChats ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/tgbot/chats',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalUserChat', value: res.data })
        })
    },
    gitDownload ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/git/latest/release',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'storageDownloads', value: res.data })
        })
    },
    gitUpdate ({ state, dispatch }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/git/update',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.data === 'OK') {
            dispatch('gitDownload')
          }
        })
    },
    getSkillsInfo ({ commit, dispatch }) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/metrics/users_count',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w',
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalUsers', value: res.data.total_users })
          dispatch('firstLesson')
          dispatch('secondLesson')
          dispatch('thirdLesson')
          dispatch('fourthLesson')
          dispatch('fivethLesson')
          dispatch('examUserCount')
          dispatch('nftRequests')
          dispatch('skillsTopReferrers')
          dispatch('skillsTopEarners')
          dispatch('skillsUserReferral')
          dispatch('skillsUserEarn')
        })
    },
    skillsUserReferral ({ commit }) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/metrics/referrals_count',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalReferral', value: res.data.total_referrals })
        })
    },
    skillsTopReferrers ({ commit }) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/metrics/top_referrers',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'topReferrers', value: res.data.top_referrers })
        })
    },
    skillsTopEarners ({ commit }) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/metrics/top_earners',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'topEarners', value: res.data.top_earners })
        })
    },
    skillsUserEarn ({ commit }) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/metrics/total_earned',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalRewards', value: res.data.total_earned })
        })
    },
    firstLesson ({ commit }) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/lessons/f1bf34ec-2f18-431f-8c38-6f27ebb772c4/stats/attendants',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then( res => {
          commit('stateUpdater', { name: 'firstLessonCount', value: res.data.total_users })
        })
    },
    secondLesson ({commit}) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/lessons/cf7d2c35-5046-4322-b405-e940a1fdebbd/stats/attendants',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'secondLessonCount', value: res.data.total_users })
        })
    },
    thirdLesson ({commit}) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/lessons/8216b963-a654-40b0-98a2-3aff97b97e7a/stats/attendants',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'thirdLessonCount', value: res.data.total_users })
        })
    },
    fourthLesson ({commit}) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/lessons/5977ebc1-a56a-4a02-b61c-3d25e4f45195/stats/attendants',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'fourthLessonCount', value: res.data.total_users })
        })
    },
    fivethLesson ({commit}) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/lessons/7076ef38-740e-409c-856c-9e032c13624f/stats/attendants',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'fivethLessonCount', value: res.data.total_users })
        })
    },
    examUserCount ({commit}) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/stats/exam_total_attempts',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'examTotalCount', value: res.data.total_attempts })
        })
    },
    nftRequests ({commit}) {
      return axios({
        method: 'get',
        url: 'https://api.defiskills.io/courses/6958fb6f-d563-43b3-bad7-1f9685139187/stats/nft_requests',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXNzaW9uIjp7IlVzZXJJZCI6IjMyN2QzYjQ4LWRiMjItNDNhZS04YjQxLWE4ZDA0NjQ2NWNiNiIsIkF1dGhNZXRob2QiOiJlbWFpbCIsIkV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTM6MzI6MzguNDYzOTU5NTczWiJ9LCJleHAiOjE2NzczMzE5NTh9.QrBtuDEebeH5_Bht7v2Bvm0pfvupGbfu747SS9chu1eBoAMp63Sce1eFobhm1YJIFBoHQWOUrttaxqv7FIUXftmnaNJGDSczftTX9uzveBQ7mCBfZnLFe4vRUq9q_QNjkB-_qcIUWTrUJPPmLZD8dyjem4XFlWvXIkIaGCrf73hLEf54rxu1D6bpL_Uk4fnYVDoqi8wUP3cBPJ8QI4bcfBO3H8R01hYZLXMiGAtep9pWaV7bRiwl0u62hKX-HXy4ou3fhz451gTAXjOijhdqBK5SURJBQLEthrXV4GhAAxbgMOo2tljuChvvc5AoQVcASiXhVHYEFAoP5ZHB81wN8w'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalNftRequest', value: res.data.total_requests })
        })
    },
    bugBountyUser ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/bounty/users_count',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalUsers', value: res.data })
        })
    },
    bugBountyTask ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/bounty/tasks',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'bountyTasks', value: res.data })
        })
    },
    bugBountyRewards ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/bounty/rewards',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'totalRewards', value: res.data })
        })
    },
    storageInfo ({ commit, state }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/git/stats/between/'+ `${state.beginTime}T00:00:00Z`+'/'+ `${state.endTime}T00:00:00Z`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'storageDownloadInfo', value: res.data })
        })
    },
    deskInfo ({ commit, state }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/desc/stats/between/'+ `${state.beginTime}T00:00:00Z`+'/'+ `${state.endTime}T00:00:00Z`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'deskStatsInfo', value: res.data })
        })
    },
    nftTotalInfo ({ commit, state }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/nft/stats/latest',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'nftStatsInfo', value: res.data })
        })
    },
    nftInfo ({ commit, state }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/nft/stats/between/'+ `${state.beginTime}T00:00:00Z`+'/'+ `${state.endTime}T00:00:00Z`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'nftStatsTotalInfo', value: res.data })
        })
    },
    nodeLastInfo ({ state }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/node/stats/latest',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          state.nodeLastTotalInfo = res.data
        })
    },
    nodeInfo ({ commit, state }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/node/stats/between/'+ `${state.beginTime}T00:00:00Z`+'/'+ `${state.endTime}T00:00:00Z`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'nftStatsInfo', value: res.data })
        })
    },
    updateNode ({ state, commit }) {
      return axios({
        method: 'get',
        url: state.baseUrl + '/node/update',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status === 200) {
            commit('stateUpdater', { name: 'updateText', value: 'Done Update Node' })
          } else {
            commit('stateUpdater', { name: 'updateText', value: 'Error Update Node' })
          }
        })
    },
    // new queries
    async getNodes ({ state, commit, getters }) {
      // await dispatch('userSession')
      commit('stateUpdater', { name: 'isNodesLoading', value: true })
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/denode/net/info'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          const sortedList = res.data.sort((a, b) => { return (a.id - b.id) })
          commit('stateUpdater', { name: 'nodes', value: sortedList })
          commit('stateUpdater', { name: 'isNodesLoading', value: false }) // for loader
          return sortedList
        })
    },
    async getNodesProofs ({ state, commit, getters }) {
      // await dispatch('userSession')
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/denode/net/proofs'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'nodeProofs', value: res.data })
        })
    },
    async getNodesEarnings ({ state, commit, getters }) {
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/denode/net/earnings'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'allNodesEarnings', value: res.data })
        })
    },
    async getEarnings ({ state, commit, dispatch }) {
      state.nodes.forEach(async (node) => {
        const res = await dispatch('getNodeEarningsById', node.id)
        state.nodesEarningInfo[node.id] = res
      })
    },
    async getNodeInfoById ({ state, commit, getters }, id) { // get Node object
      // await dispatch('userSession')
      return axios({
        method: 'get',
        url: getters.apiUrl(`api/v1/denode/${id}/info`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'node', value: res.data })
          return res.data
        })
    },
    async getNodeEarningsById ({ state, commit, getters }, id) {
      commit('stateUpdater', { name: 'nodeEarnings', value: null })
      return axios({
        method: 'get',
        url: getters.apiUrl(`api/v1/denode/${id}/earnings`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'nodeEarnings', value: res.data })
          return res.data
        })
    },
    async getProofsById ({ state, commit, getters }, id) {
      return axios({
        method: 'get',
        url: getters.apiUrl(`api/v1/denode/${id}/proofs`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'selectedNodeProofs', value: res.data })
        })
    },
    async getUsersInfo ({ state, commit, getters}) {
      // await dispatch('userSession')
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/denode/net/users'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'dataUsersInfo', value: res.data })
          return res.data
        })
    },
    async getStorageDetails ({ state, getters}) {
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/denode/net/storage'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          return res.data
        })
    },
    async loginWithSignature ({ getters, commit }, data) {
      return axios({
        method: 'post',
        url: getters.apiUrl('api/v1/login'),
        data: { message: data.message, signed_hash: data.signed_hash },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.data.bearer_token) {
            commit('stateUpdater', { name: 'login', value: true })
            commit('setUserToken', res.data.bearer_token)
            commit('setUserAddress', data.address)
          }
          return res.data
        })
    },
    async logout ({ state, getters, commit }) {
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/auth/logout'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          commit('stateUpdater', { name: 'login', value: false })
          commit('setUserAddress', null)
          commit('setUserToken', null)
          return res.data
        })
    },
    async getNonce ({ getters }, address) {
      return axios({
        method: 'post',
        url: getters.apiUrl('api/v1/nonce'),
        data: { address: address },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          return res.data
        })
    },
    async userSession ({ state, getters, commit }) {
      return axios({
        method: 'get',
        url: getters.apiUrl('api/v1/auth/session'),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.user.token
        }
      })
        .then(res => {
          return res.data
        })
    },
    setUserLogin ({ commit }, value) {
      commit('stateUpdater', { name: 'login', value: value })
    },
    async pingNodeAddress (url) {
      const start = new Date().getTime()
      return axios({
        url: 'http://' + url
      })
       .then(() => {
        const end = new Date().getTime()
        const latency = end - start
        console.log(latency)
       })
       .catch(() => {
        const end = new Date().getTime()
        const latency = end - start
        console.log(latency)
       })
    }
  }
})
