<template>
  <div class="denet-chat">
    <GaugeChart
      v-for="(index) in itemObj"
      :key="index"
      :itemObj="index"
    />
    <div style="position: absolute; top:5%; right:5%;">
      <a @click="$router.go(-1)">
        <button>back</button>
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import GaugeChart from '@/components/charts/GaugeChart'
export default {
  name: 'DenetAsistant',
  components: { GaugeChart },
  computed:{
    ...mapState(['totalUsers', 'totalRewards', 'totalReferral', 'totalUserChat']),
    itemObj () {
      return [
        {count: this.totalUsers, title: 'Denet Asistant Total user', color: 'rgb(242, 73, 92)'},
        {count: this.totalReferral, title: 'Denet Asistant Total referrals', color: 'blue'},
        {count: parseFloat(this.totalRewards).toFixed(5) + ' TB', title: 'Denet Asistant total rewards', color: 'grey'},
        {count: this.totalUserChat.rus, title: 'Denet Russian chat', color: 'green'}
      ]
    }
  },
  mounted () {
    const $this = this
    this.$nextTick(() => {
      $this.$store.dispatch('botTotalUsers')
      $this.$store.dispatch('botUserBalance')
      $this.$store.dispatch('botUserReferral')
      $this.$store.dispatch('telegramChats')
    })
  },
}
</script>
<style lang="scss">
.denet-chat{
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  flex-wrap: wrap;
}
</style>
