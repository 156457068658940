<template>
    <div class="line-chart">
      <div class="line-chart__axes">
        <canvas :id="'y'+id"></canvas>
      </div>
      <div
        ref="wrapper"
        class="line-chart__wrapper"
      >
        <div
          ref="innerWrapper"
          class="line-chart__inner-wrapper"
          :style="{ width: containerWidth + 'px'}"
        >
        
          <canvas :id="id"></canvas>
        </div>
      </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { mapState } from 'vuex';
export default {
  name: 'LineChart',
  data() {
    return {
      pressureChart: null,
      containerWidth: 0
    }
  },
  props: ['data', 'id', 'title', 'labels', 'type', 'backgroundColor', 'borderColor'],
  watch: {
    data: async function (){
      if (this.clickValue === true) {
        await this.pressureChart.destroy()
        this.renderChart()
      }
    }
  },
  computed:{
    ...mapState(['clickValue'])
  },
  async mounted() {
    await this.renderChart()
    const wrapperElement =this.$refs.wrapper
    if (wrapperElement) {
      wrapperElement.scrollLeft += this.$refs.innerWrapper.scrollWidth
    } 
  },
  methods:{
    async renderChart () {
      let ctx = document.getElementById(this.id).getContext('2d')
      let ctxAxesY = document.getElementById('y' + this.id).getContext('2d')
      this.containerWidth = this.data.length < 11 ? 1445 : this.data.length * 145
      const minMaxYAxis = Math.min(...[...this.data[0]]) + Math.max(...[...this.data[0]])
      const stepSize = minMaxYAxis < 10 ? 1 : Math.round(minMaxYAxis / 10)
      Chart.defaults.color = '#232323'
      Chart.defaults.font.family = '"Montserrat-Regular", sans-serif'
      new Chart(ctxAxesY, {
        type: this.type ? this.type : 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.title,
              data: this.data[0],
              backgroundColor: this.backgroundColor[0],
              borderColor: this.borderColor[0]
            },
            {
              label: this.title + 1,
              data: this.data[1],
              backgroundColor: this.backgroundColor[1],
              borderColor: this.borderColor[1]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          devicePixelRatio: 2.5,
          elements: {
            point: {
              radius: 0
            }
          },
          layout: {
            padding: {
              bottom: 45
            }
          },
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: stepSize,
                font: {
                  weight: 600
                },
                crossAlign: 'far'
              },
              afterFit: (ctx) => {
                ctx.width = 40
              }
            },
            x: {
              ticks: {
                display: false
              },
              grid:{
                drawTicks: false
              }
            }
          }
        }
      })
      const chart = new Chart(ctx, {
        type: this.type ? this.type : 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.title[0],
              data: this.data[0],
              backgroundColor: this.backgroundColor[0],
              borderColor: this.borderColor[0]
            },
            {
              label: this.title[1],
              data: this.data[1],
              backgroundColor: this.backgroundColor[1],
              borderColor: this.borderColor[1]
            }
          ]
        },
        options: {
          layout: {
            padding: {
              top: 10
            }
          },
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          maintainAspectRatio: false,
          devicePixelRatio: 2.5,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: stepSize,
                display: false
              },
              grid: {
                drawTicks: false,
                drawBorder: false
              },
            },
            x: {
              ticks: {
                padding: 10,
                font: {
                  weight: 600
                },
                align: 'start',
              }
            }
          },
          animation: {
          }
        }
      })
      this.pressureChart = chart
    }
  }
}
</script>
<style lang="scss" scoped>
.line-chart{
  height: 100%;
  display: flex;
  &__wrapper {
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    width: 100%;
    height: 100%;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      padding: 10px;
      background-color: rgba($color: $colorBlack, $alpha: .05);
      -webkit-border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: $colorBlack, $alpha: .2);
      -webkit-border-radius: 7px;
    }
  }
  &__inner-wrapper {
    height: 100%;
    // padding: 0 0 12px 24px;
    // @media (min-width: 1921px) {
      // padding: 0 0 .6vw 1.2vw;
    // }
  }
  &__axes {
    height: 100%;
    width: 30px;
  }
}
</style>

