<template>
  <div class="tabs">
    <span
      v-for="(item, index) in items"
      class="tabs__item"
      :class="{ 'tabs_active': activeItem === item }"
      :key="index"
      @click="setCurrentTab(item)"
    >{{ item }}</span>
  </div>
</template>
<script>
export default {
  name: 'StorageInfoTabs',
  data () {
    return {
      activeItem: 'Online',
      items: ['Online', 'DAU', 'Devices', 'Accounts', 'Sessions']
    }
  },
  methods: {
    setCurrentTab (item) {
      this.activeItem = item
      this.$emit('setTab', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabs {
    $tabs: &;
    display: flex;
    border-bottom: 2px solid $colorGreySecondary5;
    width: max-content;
    &__item {
      cursor: pointer;
      @extend %fontMedium;
      font-size: 24px;
      line-height: 29px;
      display: block;
      padding: 0 24px 8px;
      color: $colorGreySecondary3;
    }
    &_active {
      position: relative;
      color: $colorDark;
      &::after{
        content: '';
        @include setPosition(absolute, auto, auto, -2px, 0);
        height: 2px;
        width: 100%;
        background-color: $colorYellow;
        // transition: transform ease 4s, opacity ease 4s;
        // opacity: 0;
        // transform: translateX(100%);
      }
    //   &::after{
    //     transform: translateX(0%);
    //     opacity: 1;
    //   }
    //   &~#{ $tabs }__title{
    //     &::after{
    //       transform: translateX(-100%);
    //     }
    //   }
    }
  }
</style>
