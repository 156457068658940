<template>
  <transition
    name="component-fade"
    mode="out-in"
  >
    <div class="login-page">
      <div class="login-page__title">
        DeNet Analytics
      </div>
      <button
        class="login-page__btn"
        @click="connectWallet()"
      >Connect Wallet</button>
      <div
        v-if="notification.length !== 0"
        class="popup"
      >
        <transition-group
          name="component-fade"
          mode="out-in"
        >
          <span
            v-for="(item, index) in notification"
            :key="index"
            class="popup__text"
            :class="'popup_' + item.status"
          >{{ item.text }}</span>
        </transition-group>
      </div>
    </div>
    <!-- <div class="login-page">
      <div class="login-page__content">
        <div class="login-page__title">
          <span>Login DeNet Analytics</span>
        </div>
          <input
            v-on:keyup="checkInput"
            ref="admin"
            placeholder="name"
            type="text"
            class="login-page__btn"
          >
          <input
            v-on:keyup="checkInput"
            ref="password"
            placeholder="password"
            type="password"
            class="login-page__btn"
          >
          <span
            v-if="InputValue"
            style="color:#ff4949;"
          >Please enter the correct user name and password</span>
        <button
          @click="handleLogin"
          class="login-page__btn-login"
        >
          <IconLoader v-if="IconLoading" />
          <span v-else>Login</span>
        </button>
      </div>
    </div> -->
  </transition>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Web3 from 'web3'
export default {
  name: 'LoginPage',
  data () {
    return {
      AdminValue: 'admin',
      PasswordValue: '8fh$v+upA2TCHKH68LdTEWgn7D',
      IconLoading: false,
      InputValue: false,
      web3: new Web3(Web3.givenProvider),
      notification: [],
    }
  },
  computed:{
    ...mapState(['login'])
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      getNonce: 'getNonce',
      loginWithSignature: 'loginWithSignature',
      userSession: 'userSession'
    }),
    handleLogin () {
      if (this.$refs.admin.value !== '' && this.$refs.password.value !== '') {
        this.IconLoading = true
        if (this.$refs.admin.value === this.AdminValue && this.$refs.password.value === this.PasswordValue) {
          setTimeout(() =>
            this.$store.commit('stateUpdater', { name: 'login', value: true },
            this.$router.push({ path: '/'})
          ), 3000)
        } else {
          setTimeout(() =>
            this.$store.commit('stateUpdater', { name: 'login', value: false },
            this.IconLoading = false,
            this.InputValue = true
          ), 3000)
        }
      } else {
        this.InputValue = true
      }
    },
    checkInput () {
      this.InputValue=false
    },
    async connectWallet () {
      try {
        if (!window.ethereum) {
          this.notification.push({ text: 'No crypto wallet found. Please install it.', status: 'error' })
          this.deleteNotificationItem('No crypto wallet found. Please install it.')
          throw new Error('No crypto wallet found. Please install it.')
        } else {
          await window.ethereum.request({
            method: 'wallet_requestPermissions',
            params: [{ eth_accounts: {} }]
          })
          this.notification.push({ text: 'Loading', status: 'loading' })
          const getAccount = await window.ethereum.request({
            method: 'eth_requestAccounts'
          })
          const userAddress = getAccount[0]
          const getNonce =  await this.getNonce(userAddress)
          const nonce = getNonce.nonce
          const message = 'Your Address: ' + userAddress + '\nNonce: ' + nonce
          const signature = await this.web3.eth.personal.sign(message, userAddress)
          const signatureWithoutPrefix = signature.slice(2)
          const data = { message: message, signed_hash: signatureWithoutPrefix, address: userAddress }
          await this.loginWithSignature(data)
          this.$router.push({ path: '/'})
        }
      } catch (err) {
        if (err.code === -32002) {
          this.notification.push({ text: 'Metamask is already active, please check if any tab with wallet notification is open', status: 'error' })
          this.deleteNotificationItem('Metamask is already active, please check if any tab with wallet notification is open')
        } else {
          const res = this.notification.findIndex(x => x.text === 'Loading')
          this.notification.splice(res, 1)
          this.notification.push({ text: err.message, status: 'error' })
          this.deleteNotificationItem(err.message)
        }
      }
    },
    deleteNotificationItem (message) {
      setTimeout(() => {
        const res = this.notification.findIndex(x => x.text === message)
        this.notification.splice(res, 1)
      }, 2000)
    }
  }
}
</script>
<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &__title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  &__btn {
    background-color: $colorYellow;
    padding: 10px 20px;
    border-radius: 10px;
    font-variant: all-small-caps;
    &:hover {
      background-color: rgba($color: $colorYellow, $alpha: .8);
    }
  }
}
.popup {
  @extend %fontMedium;
  position: fixed;
  left: 25px;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  &__text {
    display: flex;
    margin-top: 14px;
    width: max-content;
    box-shadow: 0 15px 30px rgba(0, 0, 0, .13);
    border-radius: 12px;
    background-color: $colorWhite;
    padding: 8px 25px;

  }
  &_loading {
    &::after {
      content: '';
      width: 12px;
      animation: dots 1s infinite linear;
    }
  }
  &_error {
    background-color: $colorRed;
    color: $colorWhite;
  }
}
@keyframes dots {
  0% {
    content: '.';
  }
  30% {
    content: '..';
  }
  60%, 100% {
    content: '...';
  }
}
</style>
