<template>
  <transition-group
    name="component-fade"
    mode="out-in"
  >
    <div
      v-if="show"
      class="control-page"
    >
      <component :is="selectedComponent" />
    </div>
  </transition-group>
</template>

<script>
import LoginPage from '@/components/LoginPage.vue'
import MainPage from '@/components/parts/MainPage.vue'
import { mapState } from 'vuex'
export default {
  name: 'ControlPage',
  components: {
    LoginPage,
    MainPage
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapState(['login']),
    selectedComponent () {
      if (this.login === false) {
        return 'LoginPage'
      } else {
        return 'MainPage'
      }
    }
  },
  async mounted () {
    this.show = true
    if (this.login === false) {
      this.$router.push({ path: '/'})
    }
  }
}
</script>

<style lang="scss" scoped>
.control-page {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
</style>
