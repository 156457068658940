<template>
  <div class="defi-skills">
    <div class="defi-skills__wrap">
      <h1 class="defi-skills__title">Total Statistics of Defi Skills</h1>
      <div class="defi-skills__charts">
        <GaugeChart
          v-for="(item) in itemObj"
          :key="item"
          :itemObj="item"
        />
      </div>
      <div class="defi-skills__charts">
        <BarChart
          class="defi-skills__item"
          id="defiSkillsBarChart"
          title="Total users"
          :labels='["Lesson_1", "Lesson_2", "Lesson_3", "Lesson_4", "Lesson_5", "Exam", "NftRequest"]'
          :data='[this.firstLessonCount, this.secondLessonCount, this.thirdLessonCount, this.fourthLessonCount, this.fivethLessonCount, this.examTotalCount, this.totalNftRequest]'
          :background-color='["rgb(255, 99, 132)", "rgb(255, 159, 64)", "rgb(255, 205, 86)", "rgb(75, 192, 192)", "rgb(54, 162, 235)", "rgb(153, 102, 255)","rgb(255, 0, 0)"]'
          :border-color="'rgba(255, 255, 255, 1)'"
        />
        <PieChart
          class="defi-skills__item defi-skills__pie-chart"
          id="defiSkillsPieChart"
          :labels='["Lesson_1", "Lesson_2", "Lesson_3", "Lesson_4", "Lesson_5", "Exam", "NftRequest"]'
          :data='[this.firstLessonCount, this.secondLessonCount, this.thirdLessonCount, this.fourthLessonCount, this.fivethLessonCount, this.examTotalCount, this.totalNftRequest]'
        />
      </div>
      <div class="defi-skills__table">
        <div
          v-for="info in tableObj"
          :key="info"
          class="defi-skills__content"
        >
          <div class="defi-skills__subtitle">
            <span>{{ info.titleId }}</span>
            <span>{{ info.titleUserId}}</span>
            <span>{{ info.titleNum }}</span>
          </div>
          <div class="defi-skills__wrapper">
            <table class="defi-skills__border-table">
              <tr
                v-for="(item, index) in info.Numbers"
                :key="item"
              >
                <td class="defi-skills__border-table">{{ index + 1 }}</td>
                <td class="defi-skills__border-table">{{item.user_id}}</td>
                <td v-if="info.titleNum === 'Total Earned'" class="defi-skills__border-table">{{item.earned}}</td>
                <td v-else class="defi-skills__border-table">{{item.referred}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute; top:5%; right:5%;">
      <a @click="$router.go(-1)">
        <button>back</button>
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BarChart from '@/components/charts/BarChart'
import PieChart from '@/components/charts/PieChart'
import GaugeChart from '@/components/charts/GaugeChart'
export default {
  name: 'MdefiSkills',
  components: {
    BarChart,
    GaugeChart,
    PieChart
  },
  mounted () {
    this.$nextTick(() => {
      this.$store.dispatch('getSkillsInfo')
    })
  },
  computed: {
    ...mapState(['topEarners', 'topReferrers', 'totalRewards','totalReferral', 'totalUsers', 'firstLessonCount', 'secondLessonCount', 'thirdLessonCount', 'fourthLessonCount', 'fivethLessonCount', 'totalNftRequest', 'examTotalCount']),
    itemObj () {
      return [ {count: this.totalUsers, title: 'Defi Skills total user', color: 'rgb(242, 73, 92)'},
        {count: this.totalReferral, title: 'Defi Skills total Referral', color: '#6e685f'},
        {count: this.totalRewards, title: 'Defi Skills total earned', color: '#79588c'},
        {count: this.firstLessonCount, title: 'Lesson 1', color: '#2d4c9c'},
        {count: this.secondLessonCount, title: 'Lesson 2', color: '#2d4c9c'},
        {count: this.thirdLessonCount, title: 'Lesson 3', color: '#2d4c9c'},
        {count: this.fourthLessonCount, title: 'Lesson 4', color: '#2d4c9c'},
        {count: this.fivethLessonCount, title: 'Lesson 5', color: '#2d4c9c'},
        {count: this.totalNftRequest, title: 'NFT Requests', color: '#9c732d'},
        {count: this.examTotalCount, title: 'Exam', color: 'green'} ]
    },
    tableObj () {
      return [
        {titleId: 'Num', titleUserId: 'User Id', titleNum: 'Total Earned', Numbers: this.topEarners},
        {titleId: 'Num', titleUserId: 'User Id', titleNum: 'Total Referred', Numbers: this.topReferrers}
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/DefiSkills'
</style>
