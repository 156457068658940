<template>
  <div
    class="node-proofs-stats"
  >
    <div class="node-proofs-stats__wrapper">
      <div class="node-proofs-stats__col2">
        <span class="node-proofs-stats__title">Proofs chart</span>
        <!-- <div class="chart-date-picker"></div> -->
        <div class="node-proofs-stats__chart">
          <LineChart
            :key="getLineChartData.id"
            id="LineChart"
            :background-color="[ 'green', 'red', '#ffd600']"
            :border-color="['green', 'red', '#ffd600']"
            :data="getLineChartData.counts"
            :title="getLineChartData.titles"
            :labels="getLineChartData.dates"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/charts/LineChart.vue'
import { mapState } from 'vuex'
export default {
  name: 'NodeProofsStats',
  components: { LineChart },
  computed: {
    ...mapState(['nodeProofs']),
    getLineChartData () {
      const nodeProofsDates = this.nodeProofs.map(x => new Date(x.timestamp).setHours(0, 0, 0, 0))
      let failedProofs = this.nodeProofs.filter(x => x.failed).map(x => new Date(x.timestamp).setHours(0, 0, 0, 0))
      const dateCountRepeat = {}
      const failedProofsRepeated = {}
      nodeProofsDates.forEach(el => {
        dateCountRepeat[el] = (dateCountRepeat[el] || 0) + 1
        if (failedProofs.includes(el)) {
          failedProofs.forEach(x => {
            if (el === x) failedProofsRepeated[x] = (failedProofsRepeated[x] || 0) + 1
          })
          failedProofs = failedProofs.filter(x => x !== el)
        } else {
          failedProofsRepeated[el] = (failedProofsRepeated[el] || 0) + 0
        }
      })
      const dates = Object.keys(dateCountRepeat).map(x => new Date(Number(x)).toLocaleDateString())
      const counts = [Object.values(dateCountRepeat), Object.values(failedProofsRepeated)]
      return { counts, dates, id: Date.now(), titles: ['Total proofs count', 'Failed proofs count'] }
    }
  }
}
</script>
<style lang="scss" scoped>
.node-proofs-stats {
  &__title {
    display: block;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 36px;
    @extend %fontMedium;
    letter-spacing: .5px;
  }
  &__wrapper {
    display: flex;
  }
  &__col1 {
    @include flexPercent(20%);
  }
  &__col2 {
    display: flex;
    flex-wrap: wrap;
    @include flexPercent(80%);
    padding: 12px 12px;
  }
  &__chart {
    width: 100%;
    height: 600px;
    padding: 24px 24px 0 24px;
    font-size: 36px;
    border: 1px solid $colorGreySecondary8;
    border-radius: 16px;
  }
}
.sort-by-time {
  padding: 12px;
  display: flex;
  &__btn {
    @include flexBox(center, center);
    width: 18%;
    padding: 8px 4px;
    border: 1px solid $colorGreySecondary3;
    border-radius: 8px;
    margin-left: 8px;
    &:first-child {
      width: 46%;
      margin-left: 0;
    }
  }
  &_btn-active {
    border-color: $colorYellow;
    background-color: $colorYellow;
  }
}

</style>
