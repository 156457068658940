import ControlPage from '@/components/ControlPage.vue'
import DenetStorage from '@/components/parts/DenetStorage.vue'
import DenetDesk from '@/components/parts/DenetDesk.vue'
import DenetAsistant from '@/components/parts/DenetAsistant.vue'
import NFTStorage from '@/components/parts/NFTStorage.vue'
import NodesPage from '@/components/parts/NodeInfo/NodesPage.vue'
// import NodeID from '@/components/parts/NodeInfo/NodeID.vue'
import NodePage from '@/components/parts/nodes/NodePage.vue'
import DefiSkills from '@/components/parts/DefiSkills.vue'
import LoginPage from '@/components/LoginPage.vue'
// import StorageInfoOld from '@/components/StorageInfo/StorageInfo.vue'
import StorageInfo from '@/components/parts/storage_info/StorageInfo.vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
store.state.login = true
const routes = [
  {
    path: '/',
    name: 'ControlPage',
    component: ControlPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/skills',
    name: 'DefiSkills',
    component: DefiSkills,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/storage',
    name: 'DenetStorage',
    component: DenetStorage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/desk',
    name: 'denetdesk',
    component: DenetDesk,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tg-bot',
    name: 'DenetAsistant',
    component: DenetAsistant,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/nft',
    name: 'NFTStorage',
    component: NFTStorage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/node',
    name: 'Nodes',
    component: NodesPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/node/:nodeID',
    name: 'Datakeeper',
    component: NodePage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/download',
    name: 'downloadInfo',
    component: StorageInfo,
    meta: {
      requiresAuth: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      await store.dispatch('userSession')
      next()
    } catch {
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})

export default router
