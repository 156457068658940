<template>
  <div class="storage-info">
    <div class="storage-info__head">
      <Breadcrumbs />
    </div>
    <span class="storage-info__title">User info</span>
    <div class="storage-info__tabs">
      <Tabs @set-tab="setTab"/>
    </div>
    <div class="storage-info__wrapper">
      <div class="storage-info__col-1">
        <InfoCard
          :active-tab="activeTab"
          :card-data="cardData"
          @set-card="setCard"
        />
      </div>
      <div class="storage-info__col-2">
        <div class="chart">
          <span class="chart__title">{{ activeTab }}</span>
          <BarChart
            v-if="getTypeChart.component === 'BarChart'"
            :key="activeTab + getTypeChart.type"
            :class="'chart__bar-' + getTypeChart.type"
            :id="'BarChart'"
            :labels="activeCard === 'countries' ? ['Germany', 'Indonesia', 'France', 'China', 'Japan', 'USA'] : ['iOS', 'Android', 'Desktop']"
            :data='[65, 59, 80, 81, 56, 55]'
            :background-color='["#32A143"]'
            :border-radius="8"
            :index-axis="getTypeChart.type === 'vertical' ? 'x' : 'y'"
          />
          <LineChart
            v-if="getTypeChart.component === 'LineChart'"
            class="chart__line-chart"
            :id="'LineChart'"
            title="Total users"
            :labels='["20.12.2021", "20.01.2022", "20.02.2022", "20.03.2022", "20.12.2021", "20.01.2022", "20.02.2022", "20.03.2022", "20.12.2021", "20.01.2022", "20.02.2022", "20.03.2022", "20.12.2021", "20.01.2022", "20.02.2022", "20.03.2022"]'
            :data='[65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 100]'
            :background-color='["rgb(255, 99, 132)", "rgb(255, 159, 64)", "rgb(255, 205, 86)", "rgb(75, 192, 192)", "rgb(54, 162, 235)", "rgb(153, 102, 255)","rgb(255, 0, 0)"]'
            :border-color="'rgb(255, 99, 132)'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@/components/parts/Breadcrumbs'
import Tabs from '@/components/parts/storage_info/StorageInfoTabs.vue'
import InfoCard from '@/components/parts/storage_info/StorageInfoCard.vue'
import BarChart from '@/components/charts/BarChart.vue'
import LineChart from '@/components/charts/LineChart.vue'
import { mapActions } from 'vuex'
export default {
  name: 'StorageInfo',
  components: { Breadcrumbs, Tabs, InfoCard, BarChart, LineChart },
  data () {
    return {
      activeTab: 'Online',
      activeCard: 'countries',
      userInfo: {}
    }
  },
  computed: {
    getTypeChart () {
      if (this.activeTab === 'Online' && this.activeCard === 'countries') return { component: 'BarChart', type: 'vertical' }
      else if (this.activeTab === 'Online' && this.activeCard === 'platforms') return { component: 'BarChart', type: 'horizontal' }
      else if (this.activeTab === 'DAU' && this.activeCard === 'countries') return { component: 'BarChart', type: 'vertical' }
      else if (this.activeTab === 'DAU' && this.activeCard === 'platforms') return { component: 'LineChart' }
      else if (this.activeTab === 'Devices' && this.activeCard === 'countries') return { component: 'BarChart', type: 'vertical' }
      else if (this.activeTab === 'Devices' && this.activeCard === 'platforms') return { component: 'LineChart' }
      else if (this.activeTab === 'Accounts' && this.activeCard === 'countries') return { component: 'BarChart', type: 'vertical' }
      else if (this.activeTab === 'Accounts' && this.activeCard === 'platforms') return { component: 'LineChart' }
      else if (this.activeTab === 'Sessions' && this.activeCard === 'countries') return { component: 'BarChart', type: 'vertical' }
      else if (this.activeTab === 'Sessions' && this.activeCard === 'platforms') return { component: 'LineChart' }
      else return ''
    },
    cardData () {
      if (this.activeTab === 'Online') return { title: this.userInfo.online_count, platforms: { iOS: 0, Android: 0, Desktop: 0 }, countries: { Kazakhstan: 0, Russia: 0, Luxembourg: 0, Germany: 0 } }
      else if (this.activeTab === 'DAU') return { title: this.userInfo.dau_count, platforms: { iOS: 0, Android: 0, Desktop: 0 }, countries: { Kazakhstan: 0, Russia: 0, Luxembourg: 0, Germany: 0 } }
      else if (this.activeTab === 'Devices') return { title: 0, platforms: { iOS: 0, Android: 0, Desktop: 0 }, countries: { Kazakhstan: 0, Russia: 0, Luxembourg: 0, Germany: 0 } }
      else if (this.activeTab === 'Accounts') return { title: 0, platforms: { iOS: 0, Android: 0, Desktop: 0 }, countries: { Kazakhstan: 0, Russia: 0, Luxembourg: 0, Germany: 0 } }
      else if (this.activeTab === 'Sessions') return { title: 0, platforms: { iOS: 0, Android: 0, Desktop: 0 }, countries: { Kazakhstan: 0, Russia: 0, Luxembourg: 0, Germany: 0 } }
      else return {}
    }
  },
  async mounted () {
    this.userInfo = await this.getUsersInfo()
  },
  methods: {
    ...mapActions(['getUsersInfo']),
    setTab (tab) {
      this.activeTab = tab
    },
    setCard (card) {
      this.activeCard = card
    }
  }
}
</script>
<style lang="scss" scoped>
.storage-info {
  width: 1483px;
  padding: 0 15px 50px;
  margin: 0 auto;
  @media (max-width: 1500px) {
    width: 1300px;
  }
  @media (max-width: 1300px) {
    width: 1200px;
  }
  &__head {
    margin: 50px 0;
  }
  &__title {
    display: block;
    @extend %fontMedium;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: .05em;
    margin-bottom: 32px;
  }
  &__tabs {
    margin-bottom: 24px;
  }
  &__wrapper {
    display: flex;
  }
  &__col-1 {
    display: flex;
    flex: 0 0 19.5%;
    max-width: 19.5%;
    padding-right: 12px;
  }
  &__col-2 {
    display: flex;
    flex: 0 0 79.5%;
    max-width: 79.5%;
    padding-left: 12px;
  }
  
}
.chart {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid $colorGreySecondary8;
  border-radius: 16px;
  // padding-top: 30px;
  // padding-right: 30px;
  padding: 30px;
  &__bar-vertical {
    padding-top: 25px;
    width: 100%;
    height: 100%;
  }
  &__bar-horizontal {
    width: 100%;
    height: 100%;
  }
  &__line-chart {
    padding-top: 25px;
    width: 100%;
    min-height: 500px;
    height: 100%;
  }
  &__title {
    font-size: 12px;
    @extend %fontMedium;
    position: absolute;
    left: 30px;
  }
}
</style>