<template>
  <div
    class="node-proofs-table"
  >
    <span class="node-proofs-table__title">Proofs list</span>
    <div class="node-proofs-table-list">
      <div class="node-proofs-table-list__title-wrapper">
        <span
          v-for="(item, index) in titleContent"
          :key="index"
          :class="'node-proofs-table-list__col' + (index + 1)"
        >
          {{ item }}
        </span>
      </div>
      <div class="node-proofs-table-list__wrapper" ref="proofsList">
        <div
          v-for="(item, indexItem) in renderTable"
          :key="indexItem"
          class="node-proofs-table-list__item"
        >
          <span
            v-for="(col, key, index) in item"
            :key="index"
            :class="'node-proofs-table-list__col' + (index + 1)"
          >
            <a
              v-if="key === 'txLink'"
              class="node-proofs-table-list__link"
              target="_blank"
              :href="col"
            > {{ col }}</a>
            <span v-else>
              {{ col }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <button class="node-proofs-table__button-show" @click="pushAllProofs">Show all</button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'NodeProofsTable',
  data () {
    return {
      titleContent: ['Time/Date', 'Transaction', 'Network gas token used, Matic', 'TBY rewarded', 'TBY mined'],
      renderTable: [],
      isProofsListLoading: false,
    }
  },
  computed: {
    ...mapGetters(['convertBalanceInWei', 'convertBalance', 'toStringHash']),
    ...mapState(['nodeProofs']),
    // getProofsTable () {
    //   const obj = []
    //   this.nodeProofs.forEach(el => {
    //     const date = new Date(el.timestamp)
    //     const time = date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute:'2-digit' }) + ' / ' + date.toLocaleDateString([], { year: '2-digit', month: '2-digit', day: '2-digit' }) 
    //     obj.push({ time:  time, txLink: 'https://polygonscan.com/tx/' + this.toStringHash(el.trx_hash), gasUsed: this.convertBalance(el.gas_fee), tbyRewarded: this.convertBalance(el.tby_system_reward, -5), tbyMined: this.convertBalance(el.tby_mined, -8) })
    //   })
    //   return obj.reverse() // from early to late date
    // }
  },
  mounted() {
    this.$refs.proofsList.addEventListener('scroll', () => {
        if(this.$refs.proofsList.scrollTop + this.$refs.proofsList.clientHeight + 100 >= this.$refs.proofsList.scrollHeight) {
          this.appendProofs();
        }
      });    
  },
  created() {
    if (!this.renderTable.length) this.appendProofs()
    this.$watch('nodeProofs', () => {
      if (!this.renderTable.length) this.appendProofs()
    })
  },
  // async mounted () {
  //   const id = this.$route.params.nodeID
  //   this.getProofsById(id)
  // },
  methods: {
    ...mapActions(['getProofsById']),
    prepareProofs(arraysOfProofs) {
      const resultArray = []
      arraysOfProofs.forEach(el => {
        const date = new Date(el.timestamp)
        const time = date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute:'2-digit' }) + ' / ' + date.toLocaleDateString([], { year: '2-digit', month: '2-digit', day: '2-digit' }) 
        resultArray.push({ time:  time, txLink: 'https://polygonscan.com/tx/' + this.toStringHash(el.trx_hash), gasUsed: this.convertBalance(el.gas_fee), tbyRewarded: this.convertBalance(el.tby_system_reward, -5), tbyMined: this.convertBalance(el.tby_mined, -8) })
      })
      return resultArray // from early to late date
    },
    appendProofs() {
      const fromIndex = this.nodeProofs.length - this.renderTable.length - 20
      const toIndex = this.nodeProofs.length - this.renderTable.length
      const someProofs = this.prepareProofs(this.nodeProofs.slice(fromIndex > 0 ? fromIndex : 0, toIndex))
      this.renderTable.push(...someProofs.reverse())
    },
    async pushAllProofs() {
      this.renderTable = this.prepareProofs(this.nodeProofs).reverse()
    }
  } 
}
</script>
<style lang="scss" scoped>
.node-proofs-table {
  @extend %flexColumn;
  padding: 12px;
  width: 1156px;
  &__title {
    @extend %fontMedium;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: .5px;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  &__button-show {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 5px;
    padding: 5px 10px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
  }
}
.node-proofs-table-list {
  $tableList: &;
  border: 1px solid $colorGreySecondary8;
  border-radius: 16px;
  overflow: hidden;
  $cols: ( 'col1': 17%, 'col2': 35%, 'col3': 21%, 'col4': 13%, 'col5': 14% );
  @each $className, $value in $cols {
    &__#{ $className } {
      @include flexPercent($value);
      padding: 20px 12px;
    }
  }
  &__link {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $colorBlue;
    cursor: pointer;
  }
  &__wrapper {
    max-height: 896px;
    overflow-y: scroll;
     &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      padding: 5px;
      background-color: rgba(0,0,0,.05);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: $colorGreySecondary2, $alpha: .2);
      -webkit-border-radius: 7px;
    }
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    @extend %fontMedium;
    line-height: 16px;
    padding: 0 15px;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-top: 1px solid $colorGreySecondary8;
    background-color: rgba($color: $colorGreySecondary5, $alpha: .8);
    &:nth-of-type(odd) {
      background-color: $colorWhite;
    }
  }
}
</style>