<template>
  <div class="nodes-info-list">
    <!-- <span class="loader"></span> -->
    <div class="nodes-info-list__container">
      <div class="nodes-info-item">
        <div
          v-for="(col, key, index) in contentTitle"
          class="nodes-info-item__col nodes-info-item_title"
          :key="index"
          :class="['nodes-info-item_' + key]"
          @click="col.isSortable ? setSortValue(key) : ''"
        >
          <span :class="{ 'nodes-info-item_type-button': col.isSortable }">{{ col.title || col }}</span>
          <div
            v-if="col.isSortable && sortBy === key"
            :style="[sortIndex === -1 ? { transform: 'rotate(-90deg)' } : '']"
            class="nodes-info-item_sort-icon"
          >
            <IconArrow />
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in content"
        :key="index"
        class="nodes-info-item nodes-info-item_clickable"
        @click="$router.push(`/node/${item.id}`)"
      >
        <div
          v-for="(col, key, itemIndex) in item"
          class="nodes-info-item__col"
          :key="itemIndex"
          :class="['nodes-info-item_' + key]"
        >
          <img
            v-if="key === 'country'"
            class="nodes-info-item_flag-logo"
            :src="setImage(col)"
            alt="flag"
          >
          <span :class="col.class">{{ col.title || col }}{{ col.unit }}</span>
          <div
            v-if="key === 'owner'"
            class="copy"
            @click="col.func"
          >
            <IconCopy class="copy__icon"/>
            <span class="copy__text fm-copy__text">Copy address</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { shortenAddress, copyText } from '@/helpers/common'
import { IconCopy, IconArrow } from '@/components/icons'
import { decimalAdjust, formatBytes } from '@/scripts/control'
import { TOKEN_GAS, TOKEN_MINED_TBY, TOKEN_TBY } from '@/store/constants'

export default {
  name: 'NodesInfoTable',
  props: {
    activeTab: { type: String, default: '' },
  },
  components: { IconCopy, IconArrow },
  data () {
    return {
      sortBy: null,
      sortIndex: null,
    }
  },
  computed: {
    ...mapState(['nodes', 'nodesEarningInfo']),
    ...mapGetters(['convertBalance']),
    contentTitle () {
      let obj
      if (this.activeTab === 'NodeInfo') {
        obj = {
          id: 'ID',
          status: { title: 'Status', isSortable: true },
          version: 'Version',
          country: 'Country',
          ipAddress: 'IP address',
          owner: 'Owner',
          shared: { title: 'Shared, GiB', isSortable: true },
          available: { title: 'Available, GiB', isSortable: true },
          used: { title: 'Used, GiB', isSortable: true },
          usedPercent: { title: 'Used, %', isSortable: true },
          cpuPercent: 'CPU,\n total/used',
          updated: { title: 'Updated', isSortable: true }
        }
      } else if (this.activeTab === 'ProofsAndReward') {
        obj = {
          id: 'ID',
          owner: 'Owner',
          bMatic: { title: 'Balance, MATIC', isSortable: true },
          bTBY: { title: 'Balance, TBY', isSortable: true },
          mTBY: { title: 'Mined, TBY', isSortable: true },
          systemReward: { title: 'System reward, TBY', isSortable: true },
          totalProofs: { title: 'Total proofs', isSortable: true },
          pFM: { title: 'Proof\n for month', isSortable: true },
          pFW: { title: 'Proof\n for week', isSortable: true },
          pFD: { title: 'Proof\n for day', isSortable: true },
          proofTime: { title: 'Proof Time', isSortable: true }
        }
      }
      return obj
    },
    content () {
      let obj = []
        this.nodes.forEach((node) => {
          if (this.activeTab === 'NodeInfo') {
            const cpuPercent = !(node.resources?.cpu?.total_percent && node.resources?.cpu?.proc_percent)
              ? '-'
              : node.resources?.cpu?.total_percent?.toFixed() + ' / ' + node.resources?.cpu?.proc_percent?.toFixed()
            const usedPercent = this.getUsedPercent(node.resources?.space?.denode_shared, node.resources?.space?.denode_used)
            obj.push({
              id: node.id,
              status: this.getLatency(node.latency, node.online),
              version: node.app_version,
              country: node.country,
              ipAddress: node.ip_stat?.query,
              owner: {
                title: shortenAddress(node.owner),
                func: (el) => {
                  el.stopPropagation()
                  copyText(el, node.owner)
                }
              },
              shared: {
                title: formatBytes(node.resources?.space?.denode_shared),
                sortValue: node.resources?.space?.denode_shared || 0
              },
              available: {
                title: formatBytes(node.resources?.space?.denode_free),
                sortValue: node.resources?.space?.denode_free || 0
              },
              used: {
                title: formatBytes(node.resources?.space?.denode_used),
                sortValue: node.resources?.space?.denode_used || 0
              },
              usedPercent: { ...usedPercent, sortValue: usedPercent.title === '-' ? 0 : usedPercent.title },
              cpuPercent,
              updated: {
                title: this.getElapsedTimeSinceDate(node.updated_at),
                sortValue: new Date(node.updated_at)
              }
            })
          } else if (this.activeTab === 'ProofsAndReward') {
            const nodeEarningInfo = this.nodesEarningInfo[node.id]
            let additionalObj = {}
            if (nodeEarningInfo) {
              const time = this.getElapsedTimeSinceDate(nodeEarningInfo?.total_info.end_time)
              additionalObj = {
                systemReward: {
                  title: this.convertBalance(nodeEarningInfo?.total_info.system_reward_tby) + ' TBY',
                  sortValue: nodeEarningInfo?.total_info.system_reward_tby
                },
                totalProofs: {
                  title: nodeEarningInfo?.total_info.proofs_count.toString(),
                  sortValue: nodeEarningInfo?.total_info.proofs_count
                },
                pFM: {
                  title: nodeEarningInfo?.last_month.proofs_count.toString(),
                  sortValue: nodeEarningInfo?.last_month.proofs_count
                },
                pFW: {
                  title: nodeEarningInfo?.last_week.proofs_count.toString(),
                  sortValue: nodeEarningInfo?.last_week.proofs_count
                },
                pFD: {
                  title: nodeEarningInfo?.last_day.proofs_count.toString(),
                  sortValue: nodeEarningInfo?.last_day.proofs_count
                },
                proofTime: {
                  title: time,
                  sortValue: new Date(nodeEarningInfo?.total_info.end_time)
                }
              } 
            }
            obj.push({
              id: node.id,
              owner: {
                title: shortenAddress(node.owner),
                func: (el) => {
                  el.stopPropagation()
                  copyText(el, node.owner)
                }
              },
              bMatic: {
                title: this.convertBalance(node.balances[TOKEN_GAS]) + ' MATIC',
                sortValue: node.balances[TOKEN_GAS]
              },
              bTBY: {
                title: this.convertBalance(node.balances[TOKEN_TBY]) + ' TBY',
                sortValue: node.balances[TOKEN_TBY]
              },
              mTBY: {
                title: this.convertBalance(node.balances[TOKEN_MINED_TBY]) + ' TBY',
                sortValue: node.balances[TOKEN_MINED_TBY]
              },
              ...additionalObj
            })
          }
        })
      if (this.sortBy) {
        obj.sort((a, b) => {
          if (this.sortIndex === 1) {
            if (a[this.sortBy].sortValue !== 0 && b[this.sortBy].sortValue === 0) return -1;
            if (a[this.sortBy].sortValue === 0 && b[this.sortBy].sortValue !== 0) return 1;
            return this.sortBy === 'status' ? a[this.sortBy].sortValue - b[this.sortBy].sortValue : b[this.sortBy].sortValue - a[this.sortBy].sortValue
          } else if (this.sortIndex === -1) {
            if (b[this.sortBy].sortValue !== 0 && a[this.sortBy].sortValue === 0) return -1;
            if (b[this.sortBy].sortValue === 0 && a[this.sortBy].sortValue !== 0) return 1;
            return this.sortBy === 'status' ? b[this.sortBy].sortValue - a[this.sortBy].sortValue : a[this.sortBy].sortValue - b[this.sortBy].sortValue
          }
        })
      }
      return obj
    }
  },
  watch: {
    nodes () {
      this.getEarnings()
    }
  },
  methods: {
    ...mapActions(['getEarnings']),
    getLatency(latency, isOnline) {
      if (!isOnline) return { title: 'Offline', class: 'color-grey', sortValue: 0 }
      let time = latency
      const times = [1000000, 1000, 60, 60, 24]
      const units = ['ms', 's', 'm', 'h', 'd']
      let res = { title: '0' }
      times.forEach((x, index) => {
        time = time / x
        if (Math.floor(time) < times[index + 1] && time >= 1) res = { title: Math.floor(time), unit: units[index], sortValue: latency }
      })
      if (res.unit === 'ms') {
        if (res.title < 300) res.class = 'color-green'
        else res.class = 'color-orange'
      } else {
        res.class = 'color-red'
      }
      return res
    },
    getUsedPercent(shared, used) {
      if (shared && used) {
        const percent = (used * 100) / shared
        const percentFloor = decimalAdjust(percent, 'floor', -1)
        let res = { title: percentFloor >= 100 ? 100 : percentFloor, unit: '%' }
        if (percentFloor <= 50) {
          res.class = 'color-green'
          res.title = res.title.toString()
        } else if (percentFloor > 50 && percentFloor <= 90) {
          res.class = 'color-orange'
        } else {
          res.class = 'color-red'
        }
        return res
      } else {
        return { title: '-' }
      }
    },
    getElapsedTimeSinceDate(date) {
      const dateObj = new Date(date)
      if (dateObj.getFullYear() < 1000) return '-'
      const diffTime = Math.abs(new Date() - dateObj)
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
      return diffDays ? diffDays + 'd ago' : diffHours + 'h ago'
    },
    setSortValue (key) {
      if (this.sortBy !== key) this.sortIndex = null
      if (!this.sortIndex) {
        this.sortBy = key
        this.sortIndex = 1
      } else if (this.sortIndex === 1) {
        this.sortBy = key
        this.sortIndex = -1
      } else if (this.sortIndex === -1) {
        this.sortBy = null
        this.sortIndex = null
      }
    },
    setImage (col) {
      try {
        return require(`../../../assets/icons/flags/icon-flag-${col === 'Türkiye' ? 'turkey' : col?.toLowerCase().replaceAll(' ', '-')}.svg`)
      } catch {
        return require('../../../assets/icons/flags/icon-flag-default.svg')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.nodes-info-list {
  padding: 12px;
  &__container {
    @extend %flexColumn;
    overflow: hidden;
    border: 1px solid $colorGreySecondary8;
    border-radius: 16px;
  }
}
.nodes-info-item {
  display: flex;
  padding: 0 8px;
  border-bottom: 1px solid $colorGreySecondary8;
  &:last-child {
    border-bottom: none;
  }
  &:nth-of-type(odd) {
    background-color: rgba($color: $colorGreySecondary5, $alpha: 0.8);
  }
  &:first-child {
    background-color: transparent;
  }
  &__col {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    font-size: 14px;
    span {
      white-space: pre-line;
    }
  }
  &_sort-icon {
    display: flex;
    transform: rotate(90deg);
    transition: transform .2s linear;
    margin-left: 7px;
    margin-top: 2px;
    svg {
      height: 10px;
      width: auto;
    }
  }
  &_title {
    font-size: 12px;
    @extend %fontMedium;
  }
  &_type-button {
    cursor: pointer;
    &:hover {
      text-decoration: underline $colorGreySecondary7;
    }
  }
  &_clickable {
    cursor: pointer;
    transition: all .2s linear;
    &:hover {
      box-shadow: 2px 8px 35px rgba($color: $colorBlack, $alpha: .1);
    }
  }
  &_flag-logo {
    height: 22px;
    width: auto;
    margin-right: 5px;
  }
  $cols: (
    'id': 4.4%,
    'status': 8.2%,
    'version': 8.8%,
    'country': 10.4%,
    'ipAddress': 9.3%,
    'owner': 10.8%,
    'shared': 10%,
    'available': 10%,
    'used': 7.1%,
    'usedPercent': 7.1%,
    'cpuPercent': 7%,
    'updated': 6.9%,
    // proofs and reward cols
    'bMatic': 10%,
    'bTBY': 10%,
    'mTBY': 9.9%,
    'systemReward': 12.4%,
    'totalProofs': 8.2%,
    'pFM': 8.3%,
    'pFW': 8.3%,
    'pFD': 8.3%,
    'proofTime': 9.4%
  );
  @each $className, $value in $cols {
    &_#{ $className } {
      @include flexPercent($value);
    }
  }
}
.copy {
  $copy: &;
  cursor: pointer;
  margin: 0 5px 0 auto;
  position: relative;
  &::before {
    @include setPosition(absolute, 0, 0, 0, 0);
    content: '';
    z-index: 1;
  }
  &:hover {
    #{ $copy }__text {
      visibility: visible;
      opacity: 1;
      top: -12px;
    }
  }
  &__text {
    cursor: auto;
    @include setPosition(absolute, 0px, 50%, auto, 50%);
    transform: translate(-50%, -50%);
    padding: 3px 9px;
    font-size: 12px;
    background-color: $colorWhite;
    width: max-content;
    box-shadow: 0 15px 30px rgba($color: $colorBlack, $alpha: 0.13);
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
}
.color-grey {
  color: $colorGreySecondary3;
}
.color-green {
  color: $colorGreen;
}
.color-orange {
  color: $colorOrange;
}
.color-red {
  color: $colorRed;
}
</style>
