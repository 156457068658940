<template>
  <div
    v-if="node"
    class="node-page"
  >
    <Breadcrumbs />
    <div class="node-page__pagination">
      <span class="node-page__pagination-title">Datakeeper {{ node.id }}</span>
      <div class="node-page-pagination">
        <button
          class="node-page-pagination__previos"
          :disabled="!getPagination?.prev"
          @click="changeNode('prev')"
        >
          <IconArrowNext />
        </button>
        <span class="node-page-pagination__id">{{ node.id }}</span>
        <button
          class="node-page-pagination__next"
          :disabled="!getPagination?.next"
          @click="changeNode('next')"
        >
          <IconArrowNext />
        </button>
      </div>
    </div>
    <div class="node-page-cards">
      <InfoCard
        v-for="(card, key, index) in getCards"
        :key="index"
        :class="'node-page-cards__' + key"
        :title="key === 'col4' ? 'Balance' : ''"
        :content="card"
      >
        <template
          v-if="key === 'col3'"
          #polygonScanLink
        >
          <a
            :href="getLinkToPolygonScan"
            target="_blank"
            class="node-page-cards__link"
          >
            <span>View on PolygonScan</span>
            <IconLink class="node-page-cards__icon-link" />
          </a>
        </template>
      </InfoCard>
      <!-- <div class="node-page-cards__col4">
        <InfoCardBalance
          v-for="(card, index) in getBalanceCards"
          :key="index"
          class="node-page-cards__card-balance"
          :content="card"
        />
      </div> -->
    </div>
    <Tabs
      @set-active-tab="setActiveTab"
      :tabs="tabs"
    />
    <component :is="activeTab"/>
  </div>
</template>
<script>
import { copyText, shortenAddress } from '@/helpers/common'
import { mapActions, mapGetters, mapState } from 'vuex'
import {TOKEN_DE, TOKEN_GAS, TOKEN_MINED_TBY, TOKEN_TBY, TOKEN_RENTED_TBY, TOKEN_STAKED_TBY} from '@/store/constants'

import Breadcrumbs from '@/components/parts/Breadcrumbs'
import InfoCard from './NodeInfoCard.vue'
import InfoCardBalance from './NodeInfoCardBalance.vue'
import Tabs from './NodeInfoTabs.vue'
import StorageInfo from './NodeStorageInfo.vue'
import ProofsStats from './NodeProofsStats.vue'
import ProofsTable from './NodeProofsTable.vue'
import { IconLink, IconArrowNext } from '@/components/icons'
export default {
  name: 'NodePage',
  components: { InfoCard, InfoCardBalance, Tabs, StorageInfo, ProofsStats, ProofsTable, Breadcrumbs, IconLink, IconArrowNext },
  data () {
    return {
      activeTab: 'StorageInfo',
      tabs: {
        StorageInfo: 'Resources',
        ProofsStats: 'Proofs statistics',
        ProofsTable: 'Proofs table'
      },
      PoSContract: '0xB6b309Ae66A12d69259566220A2D0e35fE4bC556',
      NodeNFTContract: '0x9e8e137DCFd05aEC8208D379c5f5792b7aCf65c1'
    }
  },
  computed: {
    ...mapGetters(['convertBalance', 'getPreviousNextNodeById']),
    ...mapState(['node']),
    getPagination () {
      const res = this.getPreviousNextNodeById(this.node.id)
      return res
    },
    getCards () {
      return {
        col1: [
          {
            title: 'Owner:',
            subtitle: shortenAddress(this.node.owner),
            icon: true,
            func: (e) => { copyText(e, this.node.owner) },
            linkToScan: this.linkToScanWithAddress(this.node.owner)
          },
          {
            title: 'PoS contract:',
            subtitle: shortenAddress(this.PoSContract),
            icon: true,
            func: (e) => { copyText(e, this.PoSContract) },
            linkToScan: this.linkToScanWithAddress(this.PoSContract)
          },
          {
            title: 'NodeNFT contract:',
            subtitle: shortenAddress(this.NodeNFTContract),
            icon: true,
            func: (e) => { copyText(e, this.NodeNFTContract) },
            linkToScan: this.linkToScanWithAddress(this.NodeNFTContract)
          }
        ],
        col2: [
          { title: 'Status:', subtitle: this.node.online ? 'Online' : 'Offline', class: this.node.online ? 'online' : 'red' },
          { title: 'Uptime:', subtitle: '-', class: 'subtitle-green' },
          { title: 'Rank:', subtitle: this.node.rank, class: 'subtitle-green' }            
        ],
        col3: [
          { title: 'Version:', subtitle: this.node.node_info?.node_version, class: 'mb-16' },
          { title: 'Host address:', subtitle: this.getHostAddress, class: 'mb-16' },
          { title: 'Country:', subtitle: this.node.country, class: 'mb-16' }
        ],
        col4: [
          { title: 'MATIC:', subtitle: this.node.balances ? this.convertBalance(this.node.balances[TOKEN_GAS]) : null, unit: 'MATIC', flexClass: true },
          { title: 'DE:', subtitle: this.node.balances ? this.convertBalance(this.node.balances[TOKEN_DE]) : null, unit: 'DE', flexClass: true },
          { title: 'TBY:', subtitle: this.node.balances ? this.convertBalance(this.node.balances[TOKEN_TBY]) : null, unit: 'TBY', flexClass: true  },
          { title: 'TBY mined:', subtitle: this.node.balances ? this.convertBalance(this.node.balances[TOKEN_MINED_TBY]) : null, unit: 'TBY', flexClass: true },
          { title: 'TBY rented:', subtitle: this.node.balances ? this.convertBalance(this.node.balances[TOKEN_RENTED_TBY]) : null, unit: 'TBY', flexClass: true },
          { title: 'TBY staked:', subtitle: this.node.balances ? this.convertBalance(this.node.balances[TOKEN_STAKED_TBY]) : null, unit: 'TBY', flexClass: true },
        ]
      }
    },
    // getBalanceCards () {
    // },
    getHostAddress () {
      return this.node.tcp_address?.IP + ':' + this.node.tcp_address?.Port
    },
    getLinkToPolygonScan () {
      return 'https://polygonscan.com/address/' + this.node.owner
    }
  },
  async mounted () {
    // setInterval(() => console.log((this.node.balances)), 2000)
    const id = this.$route.params.nodeID
    await this.getNodeInfoById(id)
  },
  methods: {
    ...mapActions(['getNodeInfoById', 'getProofsById', 'getNodeEarningsById']),
    setActiveTab (tab) {
      this.activeTab = tab
    },
    linkToScanWithAddress (address) {
      return 'https://polygonscan.com/address/' + address
    },
    async changeNode (key) {
      const res = this.getPreviousNextNodeById(this.node.id)[key]
      this.$router.push({ path: '/node/' + res })
      await this.getInfoAboutNode(res)
    },
    async getInfoAboutNode (id) {
      this.getNodeInfoById(id)
      if (this.activeTab === 'ProofsStats') this.getNodeEarningsById(id)
      if (this.activeTab === 'ProofsTable') this.getProofsById(id)
    }
  }
}
</script>
<style lang="scss" scoped>
.node-page {
  @extend %flexColumn;
  height: 100%;
  width: 1500px;
  padding: 40px 22px 0;
  margin: 0 auto;
  &__pagination {
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    padding: 0 12px;
  }
  &__pagination-title {
    @extend %fontMedium;
    font-size: 36px;
    letter-spacing: .5px;
  }
}
.node-page-pagination {
  display: flex;
  align-items: center;
  margin-left: 25px;
  @mixin buttonStyles ($rotate) {
    display: inline-flex;
    transform: rotate($rotate);
  }
  &__previos {
    @include buttonStyles(0deg);
  }
  &__id {
    @include flexBox(center, center);
    border: 1px solid $colorGreySecondary3;
    color: $colorGreySecondary2;
    border-radius: 8px;
    height: 40px;
    width: 56px;
    margin: 0 12px;
  }
  &__next {
    @include buttonStyles(-180deg);
  }
}
.node-page-cards {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  $cols: ('col1': 24%, 'col2': 24%, 'col3': 24%, 'col4': 28%);
  @each $className, $value in $cols {
    &__#{ $className } {
      @include flexPercent($value);
    }
  }
  &__card-balance {
    @include flexPercent(50%);
  }
  &__link {
    @extend %fontMedium;
    color: $colorBlue;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  &__icon-link {
    margin-left: 4px;
  }
}
</style>
