<template>
  <div
    class="node-resources-table"
  >
    <div class="node-resources-table__title-wrapper">
      <span class="node-resources-table__type">Types</span>
      <span class="node-resources-table__size">Size, GiB</span>
      <span class="node-resources-table__percent">Count</span>
    </div>
    <div class="node-resources-table__wrapper">
      <div
        v-for="(item, index) in content"
        :key="index"
        class="node-resources-table__item"
      >
        <span class="node-resources-table__type">{{ item.filename }}</span>
        <span class="node-resources-table__size">{{ formatBytes(item.size) }}</span>
        <span class="node-resources-table__percent">{{ item.count }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { formatBytes } from '@/scripts/control'
export default {
  name: 'NodeTable',
  props: {
    content: { type: Object, default () {} }
  },
  methods: {
    formatBytes
  }
}
</script>
<style lang="scss" scoped>
@import 'nodeStyles.scss';
.node-resources-table {
  $nodeResourcesTable: &;
  @extend %flexColumn;
  @extend %commonBorderStyles;
  width: 30%;
  margin: 50px 12px 0;
  height: 328px;
  &__title-wrapper {
    display: flex;
    padding: 4px 0;
    font-size: 12px;
    @extend %fontMedium;
  }
  &__wrapper {
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      padding: 5px;
      margin-block-end: 9px;
      background-color: rgba(0,0,0,.05);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: #676767, $alpha: .2);
      -webkit-border-radius: 7px;
    }
  }
  &__item {
    display: flex;
    border-top: 1px solid #DDDDDD;
    &:nth-child(2n) {
      background-color: rgba($color: $colorGreySecondary5, $alpha: .8);
    } 
  }
  $cols: ('type': 44%, 'size': 29.5%, 'percent': 26.5%);
  @each $col, $value in $cols {
    &__#{ $col } {
      @include flexPercent($value);
      padding: 16px 0 16px 20px;
    }
  }
}
</style>
