<template>
  <div
    class="node-proofs-table"
  >
    <div class="node-proofs-table-list">
      <div class="node-proofs-table-list__title-wrapper">
        <span
          v-for="(item, index) in titleContent"
          :key="index"
          :class="'node-proofs-table-list__col' + (index + 1)"
        >
          {{ item }}
        </span>
      </div>
      <div class="node-proofs-table-list__wrapper">
        <div
          v-for="(item, indexItem) in getProofsTable"
          :key="indexItem"
          class="node-proofs-table-list__item"
        >
          <span
            v-for="(col, key, index) in item"
            :key="index"
            :class="'node-proofs-table-list__col' + (index + 1)"
          >
            <a
              v-if="key === 'txLink'"
              class="node-proofs-table-list__link"
              target="_blank"
              :href="col"
            > {{ col }}</a>
            <span v-else>
              {{ col }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'NodeProofsTable',
  data () {
    return {
      titleContent: ['Time/Date', 'Transaction', 'Network gas token used, Matic', 'TBY rewarded', 'TBY mined']
    }
  },
  computed: {
    ...mapGetters(['convertBalanceInWei', 'convertBalance', 'toStringHash']),
    ...mapState(['selectedNodeProofs']),
    getProofsTable () {
      const obj = []
      this.selectedNodeProofs.forEach(el => {
        const date = new Date(el.timestamp)
        const time = date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute:'2-digit' }) + ' / ' + date.toLocaleDateString([], { year: '2-digit', month: '2-digit', day: '2-digit' }) 
        obj.push({ time:  time, txLink: 'https://polygonscan.com/tx/' + this.toStringHash(el.trx_hash), gasUsed: this.convertBalance(el.gas_fee), tbyRewarded: this.convertBalance(el.tby_system_reward, -5), tbyMined: this.convertBalance(el.tby_mined, -8) })
      })
      return obj.reverse()
    }
  },
  async mounted () {
    const id = this.$route.params.nodeID
    this.getProofsById(id)
  },
  methods: {
    ...mapActions(['getProofsById'])
  } 
}
</script>
<style lang="scss" scoped>
.node-proofs-table {
  @extend %flexColumn;
  padding: 24px;
  width: 1156px;
}
.node-proofs-table-list {
  $tableList: &;
  border: 1px solid $colorGreySecondary8;
  border-radius: 16px;
  overflow: hidden;
  $cols: ( 'col1': 17%, 'col2': 35%, 'col3': 21%, 'col4': 13%, 'col5': 14% );
  @each $className, $value in $cols {
    &__#{ $className } {
      @include flexPercent($value);
      padding: 20px 12px;
    }
  }
  &__link {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $colorBlue;
    cursor: pointer;
  }
  &__wrapper {
    max-height: 896px;
    overflow-y: auto;
     &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      padding: 5px;
      background-color: rgba(0,0,0,.05);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: $colorGreySecondary2, $alpha: .2);
      -webkit-border-radius: 7px;
    }
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    @extend %fontMedium;
    line-height: 16px;
    padding: 0 15px;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-top: 1px solid $colorGreySecondary8;
    background-color: rgba($color: $colorGreySecondary5, $alpha: .8);
    &:nth-of-type(odd) {
      background-color: $colorWhite;
    }
  }
}
</style>