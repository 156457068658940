<template>
  <div class="bar-chart" :style="{width:widthContent}">
    <canvas width="100%" height="100%" :id="id"/>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapState } from 'vuex';
  export default {
    data() {
      return {
        pressureChart: null
      }
    },
    props: ['backgroundColor', 'widthContent', 'borderWidth', 'borderColor', 'borderRadius', 'data', 'fill', 'height', 'id', 'labels', 'title', 'type', 'width', 'indexAxis'],
    watch: {
      data: async function (){
        if (this.clickValue === true) {
          await this.pressureChart.destroy()
          await this.renderChart()
        }
      }
    },
    computed:{
      ...mapState(['clickValue'])
    },
    mounted() {
      this.renderChart()
    },
    methods:{
      renderChart () {
        let ctx = document.getElementById(this.id).getContext('2d')
        Chart.defaults.color = '#232323'
        Chart.defaults.font.family = '"Montserrat-Regular", sans-serif'
        this.pressureChart = new Chart(ctx, {
          type: this.type ? this.type : 'bar',
          data: {
            labels: this.labels,
            datasets: [{
              label: this.title,
              data: this.data,
              fill: this.fill,
              backgroundColor: this.backgroundColor,
              borderColor: this.borderColor,
              borderWidth: this.borderWidth ? this.borderWidth : 1,
              borderRadius: this.borderRadius,
              maxBarThickness: 40,
              barThickness: 40
            }]
          },
          options: {
            indexAxis: this.indexAxis,
            plugins: {
              legend: {
                display: false
              }
            },
            scales: {
              x: {
                grid: {
                  display: this.indexAxis === 'x' ? false : true,
                  drawBorder: false
                }
              },
              y: {
                grid: {
                  display: this.indexAxis === 'y' ? false : true,
                  drawBorder: false
                },
                ticks: {
                  padding: 12,
                  align: 'center',
                  crossAlign: 'far'
                }
              }
            }
          }
        })
      }
    }
  }
</script>