<template>
  <div class="node-info-card">
    <div
      class="node-info-card__wrapper"
      :class="[wrapperClass ? 'node-info-card_' + wrapperClass : '' ]"
    >
      <div class="node-info-card__col">
        <span
          v-if="title !== ''"
          class="node-info-card__col-title">{{ title }}</span>
        <div
          v-for="(item, index) in content"
          :key="index"
          :class="{ 'node-info-card__content-wrapper': item.flexClass }"
        >
          <div
            class="node-info-card__title-wrapper"
          >
            <component
              v-if="item.unit"
              :is="'Icon' + item.unit"
              class="node-info-card__unit-icon"
            />
            <span class="node-info-card__title">{{ item.title }}</span>
          </div>
          <div
            class="node-info-card__subtitle-wrapper"
            :class="'node-info-card-custom_' + item.class"
          >
            <span class="node-info-card__subtitle">
              {{ item.subtitle }}
            </span>
            <span
              v-if="item.unit"
              class="node-info-card__unit"
            >
             &nbsp;{{ item.unit }}
            </span>
            <!-- TODO: -->
            <div class="node-info-card__copy" v-if="item.icon" @click="item.func"> 
              <IconCopy class="node-info-card__icon-copy" />
              <span class="fm-copy__text node-info-card__text-copy">Copy address</span>
            </div>
            <!-- TODO: -->
            <a
              v-if="item.linkToScan"
              class="node-info-card__link-to-scan"
              :href="item.linkToScan"
              target="_blank"
            >
              <IconLink />
            </a>
          </div>
        </div>
        <slot name="polygonScanLink" />
      </div>
      <slot name="chart" />
    </div>
  </div>
</template>
<script>
import { IconCopy, IconLink } from '@/components/icons'
import { IconTBY, IconDE, IconMATIC } from '@/components/icons/units'
export default {
  name: 'NodeInfoCard',
  components: { IconCopy, IconLink, IconTBY, IconDE, IconMATIC },
  props: {
    content: { type: Array, default: () => [] },
    wrapperClass: { type: String, default: '' },
    title: { type: String, default: '' }
  },
  // mounted () {
  //   console.log(this.wrapperClass)
  // }
}
</script>
<style lang="scss" scoped>
@import './NodeInfoCard.scss';
</style>
