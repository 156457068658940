<template>
  <div class="denet-storage">
    <div class="denet-storage__wrapper">
      <h1 class="denet-storage__subtitle">General Product Analysis</h1>
      <div class="denet-storage__col">
        <div class="denet-storage__item">
          <div class="denet-storage__title"><span>{{storageDownloads.version}}:</span></div>
            <PieChart
              id="storagePieChart"
              :labels='["Linux", "Windows32", "Windows64", "Darwin"]'
              :data='[ storageDownloads.os.linux, storageDownloads.os.windows32, storageDownloads.os.windows64, storageDownloads.os.darwin]'
            />
        </div>
        <div class="denet-storage__item denet-storage__bar-chart">
          <div class="denet-storage__title"><span>Bug Bounty total users:</span></div>
            <BarChart
              id="firstChart"
              title="Total task users"
              :labels='["Task_1", "Task_2", "Task_3", "Task_4", "Task_5", "Task_6", "Task_7", "Task_8"]'
              :data='getTasks'
              :background-color='["rgb(255, 99, 132)", "rgb(255, 159, 64)", "rgb(255, 205, 86)", "rgb(75, 192, 192)", "rgb(54, 162, 235)", "rgb(153, 102, 255)","rgb(255, 0, 0)"]'
              :border-color="'rgba(255, 255, 255, 1)'"
            />
        </div>
      </div>
      <div class="denet-storage__task-list">
        <span class="denet-storage__title">Descriptions of the tasks</span>
        <ul
          v-for="(item, index) in taskName"
          :key="item"
          class="denet-storage__task-name"
        >
        <li>Task_{{index + 1}} {{item}}</li>
        </ul>
      </div>
      <h2 class="denet-storage__subtitle">Denet Storage Bug Bounty Tasks:</h2>
      <div class="denet-storage__col">
        <GaugeChart
          v-for="(index) in itemObj"
          :key="index"
          :itemObj="index"
        />
      </div>
      <h3 class="denet-storage__subtitle">Denet Storage total downloads:</h3>
      <div class="denet-storage__wrap">
        <div class="denet-storage__input">
          <input type="date" ref="FirstInput" :value="beginTime" @keyup="FirstDay"> -
          <input type="date" ref="SecondInput" :value="endTime" @keyup="SecondDay">
          <button class="denet-storage__btn" @click="sendDate">Send</button>
        </div>
        <div class="denet-storage__item denet-storage__second-bar-chart">
          <BarChart
            id="secondChart"
            title="Total downloads in day"
            :labels='listDate'
            :data='dataCount'
            :background-color='["orange"]'
            :border-color="'rgba(255, 255, 255, 1)'"
          />
        </div>
        <div class="denet-storage__item denet-storage__line-chart">
          <LineChart
            id="lineChart"
            title="Storage downloads on github"
            :labels='listDate'
            :data='dataCount'
            :background-color='["red"]'
            :border-color="'blue'"
          />
          </div>
        <TableDownloads/>
      </div>
    </div>
    <div style="position: absolute; top:5%; right:5%;">
      <a @click="$router.go(-1)">
        <button>back</button>
      </a>
    </div>
  </div>
</template>
<script>
import PieChart from '@/components/charts/PieChart'
import BarChart from '@/components/charts/BarChart'
import LineChart from '@/components/charts/LineChart'
import GaugeChart from '@/components/charts/GaugeChart'
import TableDownloads from '@/components/parts/TableDownloads'
import { mapState } from "vuex"
import { Mixin } from "@/mixins/getDate.js"
export default({
  mixins: [Mixin],
  components:{
    TableDownloads,
    PieChart,
    BarChart,
    GaugeChart,
    LineChart
  },
  data() {
    return{
      taskName: ['Upload 20 files Storage', 'Download 20 files from Storage', 'Upload 1 GB of files Storage', 'Download 1 GB of files Storage', 'Upload 10 GB files Storage', 'Download and install DeNet Pass', 'Save and Stake your NFT in DeNet NFT', 'Launch DeNet assistant in Telegram(enter public key)', 'Wiki section bot DeNet assistant']
    }
  },
  mounted () {
    this.$store.commit('stateUpdater', { name: 'selectedVersion', value: 'mainnet-v2.0.0_beta' })
    this.$store.dispatch('gitUpdate')
    this.$store.dispatch('bugBountyTask')
    this.$store.dispatch('bugBountyUser')
    this.$store.dispatch('bugBountyRewards')
    this.$store.commit('stateUpdater', { name: 'endTime', value: this.getToday })
    this.$store.commit('stateUpdater', { name: 'beginTime', value: this.sevenDaysAgo })
    this.$store.dispatch('storageInfo')
  },
  computed: {
    ...mapState(['bountyTasks', 'beginTime', 'endTime', 'selectedVersion', 'storageDownloadInfo', 'totalRewards', 'totalUsers', 'storageDownloads']),
    getTasks () {
      var tasks = []
      var bounty = (Object.values(this.bountyTasks.tasks))
      for (let k=0; k<bounty.length; k++) {
        var convertToString = bounty[k].total.toString()
        tasks.push(convertToString)
      }
      return tasks
    },
    getDiffTasks () {
      var tasks = []
      var bounty = (Object.values(this.bountyTasks.tasks))
      for (let k=0; k<bounty.length; k++) {
        var convertToString = bounty[k].diff.toString()
        tasks.push(convertToString)
      }
      return tasks
    },
    listDate () {
      var listDateArray = []
      for (let i=0; i<this.storageDownloadInfo.length; i++) {
        var lastVersion = this.storageDownloadInfo[i].time.slice(0, 10)
        const toString = lastVersion.toString()
        listDateArray.push(toString)
      }
      return listDateArray
    },
    dataCount () {
      var countArray = []
      for (let i=0; i<this.storageDownloadInfo.length; i++) {
        var lastVersion = this.storageDownloadInfo[i].versions['mainnet-v2.0.0_beta'].today
        if ( lastVersion > 100) {
          lastVersion = '6'
        }
        const toString = lastVersion.toString()
        countArray.push(toString)
      }
      return countArray
    },
    itemObj () {
      return [
        {count: this.totalUsers, title: 'Total user', color: 'rgb(242, 73, 92)'},
        {count: parseFloat(this.totalRewards).toFixed(5) + ' TB', title: 'Total rewards', color: 'green'},
        {count: this.getTasks[0], title: 'Task 1', color: 'grey',  diff:'Differents from yesterday: ' + this.getDiffTasks[0]},
        {count: this.getTasks[1], title: 'Task 2', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[1]},
        {count: this.getTasks[2], title: 'Task 3', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[2]},
        {count: this.getTasks[3], title: 'Task 4', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[3]},
        {count: this.getTasks[4], title: 'Task 5', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[4]},
        {count: this.getTasks[5], title: 'Task 6', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[5]},
        {count: this.getTasks[6], title: 'Task 7', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[6]},
        {count: this.getTasks[7], title: 'Task 8', color: 'grey', diff:'Differents from yesterday: ' + this.getDiffTasks[7]},
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/DenetStorage'
</style>
