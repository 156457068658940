<template>
  <div class="denet-nft">
    <div class="denet-nft__content">
      <div class="denet-nft__wrap">
        <GaugeChart
          v-for="(index) in itemObj"
          :key="index"
          :itemObj="index"
        />
      </div>
      <div
        style="display: 'block'"
        class="denet-nft__wrap"
      >
        <div
          v-for="item in itemObj"
          :key="item"
          class="denet-nft__contracts"
        >
          <span><b>{{ item.title }}: </b></span>
          <span> {{ item.contract }}</span>
        </div>
      </div>
      <div
        style="display: 'block'"
        class="denet-nft__wrap"
      >
        <input type="date" ref="FirstInput" :value="beginTime" @keyup="FirstDay"> -
        <input type="date" ref="SecondInput" :value="endTime" @keyup="SecondDay">
        <button class="denet-nft__btn" @click="sendDate">Send</button><br>
        <div class="denet-nft__contracts">
          <span><b>DeNet WrappedNFT v1.1.0_beta_2:</b> 0x781Bf75092b6D0fF382861E578519773C26c131b</span>
        </div>
        <div class="denet-nft__bar-chart">
          <BarChart
            id="wrapnftChart"
            title="DeNet WrappedNFT v1.1.0_beta_2"
            :labels='dateList'
            :data='getWrapData'
            :background-color='["rgb(255, 99, 23)"]'
            :border-color="'rgba(255, 255, 255, 1)'"
          />
        </div>
          <div class="denet-nft__contracts">
          <span><b>DeNet Mint NFT</b> 0xD91c3B1B45Dd9a9c55CEE4aAC419b75D3645fd33</span>
        </div>
        <div class="denet-nft__bar-chart">
          <BarChart
            id="minNFTChart"
            title="DeNet Mint NFT"
            :labels='dateList'
            :data='getMintNFTData'
            :background-color='["rgb(255, 99, 23)"]'
            :border-color="'rgba(255, 255, 255, 1)'"
          />
        </div>
      </div>
    </div>
    <div style="position: absolute; top:5%; right:5%;">
      <a @click="$router.go(-1)">
        <button>back</button>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GaugeChart from '@/components/charts/GaugeChart'
import BarChart from '@/components/charts/BarChart'
import { Mixin } from "@/mixins/getDate.js"
export default {
  name: 'DenetNftStorage',
  mixins: [Mixin],
  components: {
    GaugeChart,
    BarChart
  },
  computed:{
    ...mapState(['nftStatsInfo', 'endTime', 'beginTime', 'nftStatsTotalInfo']),
    itemObj () {
      return [
        { count: this.nftStatsInfo.contracts.mint['0xD91c3B1B45Dd9a9c55CEE4aAC419b75D3645fd33'].total, diff: 'today: '+this.nftStatsInfo.contracts.mint['0xD91c3B1B45Dd9a9c55CEE4aAC419b75D3645fd33'].today,  title: 'DeNet Mint NFT', color: 'rgb(242, 73, 92)', contract: '0xD91c3B1B45Dd9a9c55CEE4aAC419b75D3645fd33' },
        { count: this.nftStatsInfo.contracts.wrap['0x46204b92B99e8e63f4834519A9c1592A86a1f647'].total, diff: 'today: '+this.nftStatsInfo.contracts.wrap['0x46204b92B99e8e63f4834519A9c1592A86a1f647'].today, title: 'DeNet WrappedNFT', color: 'rgb(19, 92, 17)', contract: '0x46204b92B99e8e63f4834519A9c1592A86a1f647' },
        { count: this.nftStatsInfo.contracts.wrap['0xbC31bB89E6E71565a1f8F7A496748cB3E86D75CA'].total, diff: 'today: '+this.nftStatsInfo.contracts.wrap['0xbC31bB89E6E71565a1f8F7A496748cB3E86D75CA'].today, title: 'DeNet WrappedNFT v1.1.0_beta_1', color: 'rgb(112, 44, 17)', contract: '0xbC31bB89E6E71565a1f8F7A496748cB3E86D75CA' },
        { count: this.nftStatsInfo.contracts.wrap['0x781Bf75092b6D0fF382861E578519773C26c131b'].total, diff: 'today: '+this.nftStatsInfo.contracts.wrap['0x781Bf75092b6D0fF382861E578519773C26c131b'].today, title: 'DeNet WrappedNFT v1.1.0_beta_2', color: 'rgb(17, 33, 112)', contract: '0x781Bf75092b6D0fF382861E578519773C26c131b' }
      ]
    },
    dateList () {
      var dateList = []
      for (let i=0; i<this.nftStatsTotalInfo.length; i++) {
        var getDate = this.nftStatsTotalInfo[i].time.slice(0, 10)
        dateList.push(getDate)
      }
      return dateList
    },
    getWrapData () {
      var nftwrapDataList = []
      for (let i=0; i<this.nftStatsTotalInfo.length; i++) {
        var getNftWrapData = this.nftStatsTotalInfo[i].contracts.wrap['0x781Bf75092b6D0fF382861E578519773C26c131b'].today
        nftwrapDataList.push(getNftWrapData)
      }
      return nftwrapDataList
    },
    getMintNFTData () {
      var getMintDataList = []
      for (let i=0; i<this.nftStatsTotalInfo.length; i++) {
        var getNftMintData = this.nftStatsTotalInfo[i].contracts.mint['0xD91c3B1B45Dd9a9c55CEE4aAC419b75D3645fd33'].today
        getMintDataList.push(Math.abs(getNftMintData))
      }
      return getMintDataList
    }
  },
  mounted () {
    const $this = this
    this.$nextTick(() => {
      $this.$store.dispatch('nftTotalInfo')
      this.$store.commit('stateUpdater', { name: 'endTime', value: this.getToday })
      this.$store.commit('stateUpdater', { name: 'beginTime', value: this.sevenDaysAgo })
      $this.$store.dispatch('nftInfo')
    })
  },
}
</script>

<style lang="scss" scoped>
.denet-nft{
  display: flex;
  &__content{
    overflow-y: auto;
    height: 100vh;
  }
  &__wrap{
    display: flex;
    padding: 40px 0px;
    flex-wrap: wrap;
  }
  &__contracts{
    padding: 13px;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
  }
  &__bar-chart{
    max-width: 80%;
    flex: 0 0 80%;
    padding: 5px 50px 50px;
  }
}
</style>
