<template>
  <div class="node-info-card">
    <div class="node-info-card__wrapper">
      <div class="node-info-card__title-wrapper">
        <component
          v-if="content.unit"
          :is="'Icon' + content.unit"
          class="node-info-card__unit-icon"
        />
        <span class="node-info-card__title">{{ content.title }}</span>
      </div>
      <div class="node-info-card__subtitle-wrapper node-info-card-custom_fs-36 node-info-card-custom_mb-16">
        <span class="node-info-card__subtitle">{{ content.subtitle }}</span>
        <span
          v-if="content.unit"
          class="node-info-card__unit node-info-card-custom_unit-fs-24"
        >&nbsp;{{ content.unit }}</span>
      </div>
      <template
        v-if="content.dates"
      >
        <div
          v-for="(item, index) in content.dates"
          :key="index"
          class="node-info-card__wrapper-flex"
        >
          <span class="node-info-card__title">
            {{ ['Month:', 'Week:', 'Day:'][index] }}
          </span>
          <span
            class="node-info-card__subtitle node-info-card-custom_mb-16"
            :class="[{ 'node-info-card-custom_color-red': content.failed }, { 'node-info-card-custom_color-green': !content.failed }]"
          >
            &nbsp;{{ !content.failed ? '+' : '' }}{{ item }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { IconTBY, IconDE, IconMATIC } from '@/components/icons/units'
export default {
  name: 'NodeInfoCardBalance',
  components: { IconTBY, IconDE, IconMATIC },
  props: {
    content: { type: Object, default () {} }
  }
}
</script>
<style lang="scss" scoped>
@import './NodeInfoCard.scss';
</style>