<template>
  <div class="info-card">
    <div class="info-card__wrapper">
      <span
        class="info-card__text"
        :class="{ 'info-card_online': activeTab === 'Online' }"
      >{{ activeTab }}</span>
      <div class="info-card__total-count">
        <span>{{ cardData.title }}</span>
        &nbsp;
        <span
          v-if="['Devices', 'Accounts'].includes(activeTab)"
          class="info-card_green"
        >+ 0</span>
      </div>
    </div>
    <div class="info-card__btn-wrapper">
      <button
        class="info-card__btn"
        :class="{ 'info-card_btn-active':activeItem === getVariableCurrentTab.buttons[0] }"
        @click="cardDataChange(0)"
      >Platforms</button>
      <button
        class="info-card__btn"
        :class="{ 'info-card_btn-active':activeItem === getVariableCurrentTab.buttons[1] }"
        @click="cardDataChange(1)"
      >Countries</button>
    </div>
    <div class="info-card__wrapper info-card_height-max-percent">
      <div
        v-for="item, key, index in cardData[activeItem]"
        :key="index"
        class="info-card__item"
      >
        <span class="info-card__text">{{ key }}:</span>
        <div class="info-card__count">
          <span>{{ item }}</span>
          &nbsp;
          <span
            v-if="['Devices', 'Accounts'].includes(activeTab)"
            class="info-card_green"
          >+ 0</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InfoCard',
  props: {
    activeTab: { type: String, required: true },
    cardData: { type: Object, required: true }
  },
  emits: ['setCard'],
  data () {
    return {
      activeItem: 'countries',
      // cardData: {
      //   platforms: {
      //     iOS: 250,
      //     Android: 180,
      //     Desktop: 156
      //   },
      //   countries: {
      //     Kazakhstan: 250,
      //     Russia: 180,
      //     Luxembourg: 156,
      //     Germany: 80
      //   }
      // }
    }
  },
  computed: {
    getVariableCurrentTab () {
      if (this.activeTab === 'Online') return { buttons: ['platforms', 'countries'], }
      if (this.activeTab === 'DAU') return { buttons: ['platforms', 'countries'], }
      if (this.activeTab === 'Devices') return { buttons: ['platforms', 'countries'], }
      if (this.activeTab === 'Accounts') return { buttons: ['platforms', 'countries'], }
      if (this.activeTab === 'Sessions') return { buttons: ['platforms', 'countries'], }
      else return {}
    }
  },
  methods: {
    cardDataChange (id) {
      const buttons = this.getVariableCurrentTab.buttons
      this.activeItem = buttons[id]
      this.$emit('setCard', buttons[id])
    }
  }
}
</script>
<style lang="scss" scoped>
.info-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid $colorGreySecondary8;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_height-max-percent {
    // height: 100%;
  }
  &__text {
    color: $colorGreySecondary2;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  &__total-count {
    font-size: 32px;
    height: 44px;
    letter-spacing: .05em;
    @extend %fontMedium;
  }
  &__btn-wrapper {
    display: flex;
    margin-bottom: 16px;
    height: 40px;
  }
  &__btn {
    width: 50%;
    height: 40px;
    border: 1px solid $colorGreySecondary3;
    border-radius: 8px;
    margin-right: 4px;
    &:last-child {
        margin-right: 0;
        margin-left: 4px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__count {
    font-size: 24px;
    height: 29px;
    letter-spacing: .05em;
    @extend %fontMedium;
  }
  &_btn-active {
    border: 1px solid $colorYellow;
    background-color: $colorYellow;
  }
  &_online {
    position: relative;
    display: flex;
    &::before {
      content: '';
      display: block;
      margin: auto 4px auto 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $colorGreen;
    }
  }
  &_green {
    font-size: 14px;
    color: $colorGreenLight;
  }
}
</style>
