<template>
  <div class="table-metrics">
    <div class="table-metrics__select-version">
      <SelectVersion
        :versions="['mainnet-v1.0.0', 'mainnet-v1.0.1', 'mainnet-v1.1.0', 'mainnet-v1.2.0_beta_1', 'mainnet-v1.2.0_beta_2', 'mainnet-v1.2.0_beta_3', 'mainnet-v1.2.1_beta_4', 'mainnet-v1.2.2_beta', 'mainnet-v1.3.3_beta', 'mainnet-v1.4.0_beta', 'mainnet-v1.4.1_beta', 'mainnet-v1.4.2_beta', 'mainnet-v2.0.0_beta', 'mainnet-v1.4.3_beta', 'testnet-v0.2.0', 'testnet-v0.3.0', 'testnet-v0.3.1']"
      />
    </div>
    <div class="table-metrics__wrapper">
      <div class="table-metrics__col">
        <div class="table-metrics__content">
          <table class="table-metrics__border-table">
            <tr>
              <div class="table-metrics__header-time">
                <span>Time</span>
              </div>
                <th
                  v-for="(index) in storageDownloadInfo"
                  :key="index"
                  class="table-metrics__border-table"
                >
              <div class="table-metrics__date">
                {{index.time.slice(0, 10)}}
              </div>
              </th>
            </tr>
            <tr>
              <td class="table-metrics__border-table table-metrics__item">Total Downloads in day</td>
              <td
                v-for="(index) in storageDownloadInfo"
                :key="index"
                class="table-metrics__border-table">
                {{ index.versions[selectedVersion].today }}
              </td>
            </tr>
              <tr>
                <td class="table-metrics__border-table table-metrics__item">Total</td>
                <td
                v-for="(index) in storageDownloadInfo"
                :key="index"
                class="table-metrics__border-table">
                {{index.versions[selectedVersion].downloads}}
              </td>
              </tr>
              <tr>
                <td class="table-metrics__border-table table-metrics__item">Windows64</td>
                <td
                  v-for="(index) in storageDownloadInfo"
                  :key="index"
                  class="table-metrics__border-table"
                >
                  <span
                    v-for="item in index.versions[selectedVersion]"
                    :key="item"
                  >{{item.windows64 }}</span>
              </td>
              </tr>
              <tr>
                <td class="table-metrics__border-table table-metrics__item">Windows32</td>
                <td
                  v-for="(index) in storageDownloadInfo"
                  :key="index"
                  class="table-metrics__border-table"
                >
                  <span
                    v-for="item in index.versions[selectedVersion]"
                    :key="item"
                  >{{item.windows32 }}</span>
                </td>
              </tr>
              <tr>
                <td class="table-metrics__border-table table-metrics__item">Linux</td>
                <td
                  v-for="(index) in storageDownloadInfo"
                  :key="index"
                  class="table-metrics__border-table"
                >
                  <span
                    v-for="item in index.versions[selectedVersion]"
                    :key="item"
                  >{{item.linux }}</span>
                </td>
              </tr>
              <tr>
                <td class="table-metrics__border-table table-metrics__item">Darwin</td>
                <td
                  v-for="(index) in storageDownloadInfo"
                  :key="index"
                  class="table-metrics__border-table">
                  {{index.versions[selectedVersion].os.darwin}}
              </td>
              </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectVersion from '@/components/parts/SelectVersion'
import { mapState } from 'vuex'
export default {
  name: 'TableDownloads',
  components:{
    SelectVersion
  },
  computed:{
    ...mapState(['storageDownloadInfo', 'selectedVersion'])
  }
}
</script>

<style lang="scss" scoped>
.table-metrics{
  padding: 40px 0;
  &__select-version {
    margin-left: auto;
    min-width: max-content;
    position: relative;
    width: max-content;
    margin-left: auto;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  &__wrapper{
    position: relative;
    height: 30vw;
  }
  &__content{
    text-align: center;
    padding: 60px;
    position: relative;
    transform: rotate(90deg);
    right: 0px;
    bottom: 0;
    left: 0;
    top: 0;
  }
  &__header-time{
    font-weight: 600;
    padding: 10px 0;
    text-align: center;
    width:230px;
    height: 100%;
  }
  &__item{
    font-weight: 600;
    padding: 10px 0;
  }
  &__date{
    width:100px;
    height: 100%
  }
  &__col{
    width: 30%;
    position: absolute;
    height: 1100px;
    overflow-y: scroll;
    left: 450px;
    top: -350px;
    transform: rotate(-90deg);
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__chart{
    padding: 20px 0;
    align-items: center;
  }
  &__info{
    font-size: 20px;
    font-weight: 500;
  }
  &__border-table{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px 25px;
  }
  &__subtitle{
    display:flex;
    justify-content: space-between;
    margin: 15px 0;
    font-weight: 600;
  }
}
</style>

