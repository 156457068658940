function copyText (el, data) {
  navigator.clipboard.writeText(data)
    .then(() => {
      const oldTooltipText = el.target.parentNode.querySelector('.fm-copy__text').innerHTML
      el.target.parentNode.querySelector('.fm-copy__text').innerHTML = 'Copied!'
      setTimeout(function () {
        el.target.parentNode.querySelector('.fm-copy__text').innerHTML = oldTooltipText
      }, 2000)
    })
}

function shortenAddress (address) {
  return address ? address.slice(0, 6) + '...' + address.slice(-4) : false
}

function shortenTitle (title, st = 18, length = 24) {
  /* Shorten long title by adding dots */
  let startPoint
  if (st) startPoint = st
  if (title && title.length > length) return title.slice(0, startPoint) + '...' + title.slice(-3)
  else return title
}

function bigNumberToFixed (number, decLength = 4) {
  if (number) {
    const int = parseInt(number)
    const dec = number.toString().substring(1, 2 + decLength)
    if (number.toString().match(/e/)) return int + dec
    else if (parseFloat(int + dec) - Math.floor(parseFloat(int + dec)) === 0) return int
    else return parseFloat(number).toFixed(decLength)
  } else { return 0 }
}

function formatBytes (bytes, decimals = 2, type) {
  /** get file size and convert to possible nearest unit */
  if ([0, null, undefined].includes(bytes)) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.abs(Math.log(bytes)) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function selectSingleItem (obj) {
  const fileObj = {}
  fileObj.key = obj.entryKey
  fileObj.name = obj.name
  fileObj.size = obj.size
  fileObj.parentDir = obj.dirKey
  fileObj.type = obj.isFolder ? 'dir' : 'file'
  return fileObj
}

function getStyledTime (time) {
  const day = parseInt(time / 60 / 60 / 24)
  const hours = parseInt((time - day * 60 * 60 * 24) / 60 / 60)
  const minutes = parseInt((time - day * 60 * 60 * 24 - hours * 60 * 60) / 60)
  const seconds = parseInt((time - day * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60))
  let dayText = ''
  if (day > 0) { dayText += day + 'd ' }
  if (hours > 0) { dayText += hours + 'h ' }
  if (minutes > 0) { dayText += minutes + 'm ' }
  if (seconds > 0) { dayText += seconds + 's ' }
  return dayText
}

function inputAmountCharLength (input) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx.font = '16px Montserrat-Regular'
  const width = ctx.measureText(input).width
  return width
}

function numberWithCommas (x) {
  if (parseFloat(x) % 1 === 0) {
    return (parseFloat(x).toString().length > 4) ? parseFloat(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(x)
  } else {
    const floatNumber = parseFloat(x).toFixed(3)
    const floorNumber = Math.floor(Number(floatNumber))
    const fraction = parseFloat(floatNumber - floorNumber).toFixed(3)
    const withComma = floorNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + fraction.toString().substring(1)
    return (floorNumber.toString().length > 4) ? withComma : floatNumber
  }
}

function rectangleSelect (inputElements, selectionRectangleInitial) {
  const elements = []
  inputElements.forEach(function (element) {
    const box = element.getBoundingClientRect()
    const selectionRectangle = selectionRectangleInitial.getBoundingClientRect()
    if (
      selectionRectangle.left <= box.left + box.width &&
      selectionRectangle.left + selectionRectangle.width >= box.left &&
      selectionRectangle.top <= box.top + box.height &&
      selectionRectangle.top + selectionRectangle.height >= box.top
    ) {
      elements.push(element)
    }
  })
  return elements
}

function selectFilesByRectangle (selection) {
  const dataKeys = []
  const getFiles = [...document.querySelectorAll('.fm-listing__icon')]
  rectangleSelect(getFiles, selection).forEach(function (box) {
    dataKeys.push(box.parentNode.getAttribute('data-key'))
  })
  return dataKeys
}

function timeConverter (unixTimestamp) {
  const date = new Date(unixTimestamp * 1000).toLocaleDateString('en-EN')
  const time = new Date(unixTimestamp * 1000).toLocaleTimeString('ru-RU')
  const formattedTime = date + ' ' + time
  if (unixTimestamp === 0) {
    return '--'
  } else {
    return formattedTime
  }
}

function getTimeLeft (time) {
  const addZero = function (num) {
    return (num.toString().length === 1) ? '0' + num.toString() : num
  }
  if (time) {
    const timer = setInterval(function () {
      const day = time / 60 / 60 / 24
      const hours = (time - day * 60 * 60 * 24) / 60 / 60
      const minutes = (time - day * 60 * 60 * 24 - hours * 60 * 60) / 60
      const seconds = (time - day * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60)
      let dayText
      if (day > 1) {
        dayText = day + ' days '
      } else if (day === 1) {
        dayText = day + ' day '
      } else {
        dayText = ''
      }
      time--
      if (day === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(timer)
        return 'reload'
      } else {
        return dayText + addZero(hours) + 'h ' + addZero(minutes) + 'm ' + addZero(seconds) + 's'
      }
    }, 1000)
  } else {
    return 'reload'
  }
}

function filterInputKey (e) {
  const key = e.key
  // If is '.' key, stop it
  if (key === '.') return e.preventDefault()
  // OPTIONAL
  // If is 'e' key, stop it
  if (key === 'e') return e.preventDefault()
}

function stringToFixed (amount, length) {
  const t = amount.toString().split('.')[0]
  const t2 = amount.toString().split('.')[1]
  return t2 ? t + '.' + t2.substring(0, length) : t
}

export {
  copyText,
  shortenAddress,
  shortenTitle,
  bigNumberToFixed,
  formatBytes,
  timeout,
  getStyledTime,
  inputAmountCharLength,
  numberWithCommas,
  rectangleSelect,
  selectFilesByRectangle,
  timeConverter,
  getTimeLeft,
  filterInputKey,
  selectSingleItem,
  stringToFixed
}
