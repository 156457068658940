<template>
  <div class="nodes-page">
    <Breadcrumbs />
    <Tabs
      @set-active-tab="setActiveTab"
      :tabs="tabs"
    />
    <div
      v-if="activeTab === 'NodeInfo'"
      class="nodes-info"
    >
      <div class="nodes-info__wrapper">
        <template
          v-for="(item, key, index) in getInfoCard"
          :key="index"
        >
          <InfoCard
            v-if="!item.isMultiCard"
            :content="item.card"
            :wrapperClass="item.wrapperClass"
            :class="'nodes-info__' + key"
          >
            <template
              v-if="item.type === 'pie-chart'"
              #chart
            >
              <PieChart
                :key="key +item.time"
                class="nodes-info__pie-chart"
                :id="key + item.time"
                type="doughnut"
                :labels="['Available', 'Stored']"
                :data="item.data"
                :nodes-have-space="item.haveSpaceIDs"
              />
            </template>
          </InfoCard>
          <div
            v-else
            :class="'nodes-info__' + key"
          >
            <InfoCard
              :content="item.card1"
            >
              <template
                v-if="item.card1[0].href"
                #polygonScanLink
              >
                <a
                  :href="item.card1[0].href"
                  target="_blank"
                  class="nodes-info__link"
                >
                  <span>View on PolygonScan</span>
                  <IconLink class="nodes-info__icon-link" />
                </a>
              </template>
            </InfoCard>
            <InfoCard
              :content="item.card2"
            >
              <template
                v-if="item.card2[0].href"
                #polygonScanLink
              >
                <a
                  :href="item.card2[0].href"
                  target="_blank"
                  class="nodes-info__link"
                >
                  <span>View on PolygonScan</span>
                  <IconLink class="nodes-info__icon-link" />
                </a>
              </template>
            </InfoCard>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="activeTab === 'ProofsAndReward'"
      class="proofs-and-reward"
    >
      <div class="proofs-and-reward__wrapper">
        <InfoCardBalance
          v-for="(card, index) in getBalanceCards"
          :key="index"
          :content="card"
        />
      </div>
    </div>
    <!-- <div></div> -->
    <!-- <NodesInfoCards v-if="false"/> -->
    <!-- <NodesInfoCharts v-if="false"/> -->
    <NodesInfoTable
      :key="activeTab"
      :active-tab="activeTab"
    />
    <!-- <NodeItemList v-if="activeTab === 'NodeInfo'"/> -->
    <AllProofsStats v-if="activeTab === 'ProofsAndReward'" />
    <AllProofsTable v-if="activeTab === 'ProofsAndReward'" />
    <NodeTable :content="getStoredFiles" />
    <!-- <NodeListChart v-if="false"/> -->
    <!-- <NodeLogs v-if="false"/> -->
  </div>
</template>

<script>
// import NodesInfoCards from './NodesInfoCards.vue'
// import NodesInfoCharts from './NodesInfoCharts.vue'
import Breadcrumbs from '@/components/parts/Breadcrumbs'
import PieChart from '@/components/charts/PieChart.vue'
import NodeTable from './NodeTable.vue'
// import NodeItemList from './NodeItemList.vue'
// import NodeListChart from './NodeListChart.vue'
// import NodeLogs from './NodeLogs.vue'
import Tabs from '../nodes/NodeInfoTabs.vue'
import InfoCard from '../nodes/NodeInfoCard.vue'
import NodesInfoTable from '../nodes/NodesInfoTable.vue'
import InfoCardBalance from '../nodes/NodeInfoCardBalance.vue'
import AllProofsStats from '../nodes/AllNodesProofsStats.vue'
import AllProofsTable from '../nodes/AllNodesProofsTable.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { copyText, shortenAddress } from '@/helpers/common'
import { formatBytes } from '@/scripts/control'
import { IconLink } from '@/components/icons'
import { TOKEN_GAS, TOKEN_MINED_TBY, TOKEN_TBY } from '@/store/constants'

export default ({
  components: {
    Breadcrumbs,
    // NodesInfoCards,
    // NodeItemList,
    // NodeListChart,
    NodesInfoTable,
    // NodesInfoCharts,
    // NodeLogs,
    Tabs,
    InfoCard,
    InfoCardBalance,
    PieChart,
    AllProofsStats,
    AllProofsTable,
    NodeTable,
    IconLink
  },
  data () {
    return {
      activeTab: 'NodeInfo',
      nodesUpdated: 0,
      tabs: {
        NodeInfo: 'Node info',
        ProofsAndReward: 'Proofs and reward'
      },
      PoSContract: '0xB6b309Ae66A12d69259566220A2D0e35fE4bC556',
      NodeNFTContract: '0x9e8e137DCFd05aEC8208D379c5f5792b7aCf65c1',
      storageDetails: {},
    }
  },
  computed: {
    ...mapState(['nodes', 'nodeProofs', 'allNodesEarnings']),
    ...mapGetters(['convertBalance']),
    getStoredFiles () {
      const storedPolygon = this.storageDetails.storage?.networks?.polygon
      const obj = storedPolygon?.other_files
      // console.log(obj)
      let sortedObj = []
      if (obj) {
        obj.providers_count = { filename: 'providers count', count: storedPolygon.providers_count || 0, size: 0 }
        obj.files_count = { filename: 'files count', count: storedPolygon.files_count || 0, size: 0 }
        obj.parts_count = { filename: 'parts count', count: storedPolygon.parts_count || 0, size: storedPolygon.parts_count * 1048576 }
        obj.deprecated_parts_count = { filename: 'deprecated parts count', count: storedPolygon.deprecated_parts_count, size: storedPolygon.deprecated_parts_count * 1048576 }
        sortedObj = Object.values(obj).sort((a, b) => b.size - a.size)
      }
      return sortedObj
    },
    getInfoCard () {
      return {
        col1: {
          card: [
            { title: 'Available / Total:', subtitle: '0 / 0', class: 'fs-36' },
            { title: 'Online / Registered:', subtitle: this.getTotalOnlineNodes, class: 'fs-24' },
            { title: 'May be stolen:', subtitle: '0', class: 'fs-24' },
            { title: 'Average uptime percent:', subtitle: '0', class: 'fs-24' }
          ]
        },
        col2: {
          card1: [
            { title: 'Proof of Storage contract', subtitle: shortenAddress(this.PoSContract), icon: true, href: 'https://polygonscan.com/address/' + this.PoSContract, func: (e) => { copyText(e, this.PoSContract) }, },
          ],
          card2: [
            { title: 'Node NFT contract:', subtitle: shortenAddress(this.NodeNFTContract), icon: true, href: 'https://polygonscan.com/address/' + this.NodeNFTContract, func: (e) => { copyText(e, this.NodeNFTContract) }, }
          ],
          isMultiCard: true
        },
        col3: {
          card: [
            { title: 'Real size:', subtitle: formatBytes(this.getAllSpace.real_size), class: 'fs-24' },
            { title: 'Real stored:', subtitle: formatBytes(this.getAllSpace.real_used), class: 'fs-24' },
            { title: 'Real available:', subtitle: formatBytes(this.getAllSpace.real_free), class: 'fs-24' },
          ],
          wrapperClass: 'flex-center',
          data: this.getDataForChart,
          type: 'pie-chart',
          time: Date.now(),
          haveSpaceIDs: {'Stored': this.getAllSpace.real_usedIDs, 'Available': this.getAllSpace.real_freeIDs}
        },
        col4: {
          card: [
            { title: 'Shared:', subtitle: formatBytes(this.getAllSpace.denode_shared), class: 'fs-24' },
            { title: 'Stored:', subtitle: formatBytes(this.getAllSpace.denode_used), class: 'fs-24' },
            { title: 'Available:', subtitle: formatBytes(this.getAllSpace.denode_free), class: 'fs-24' },
          ],
          wrapperClass: 'flex-center',
          data: this.getDeNodeDataForChart,
          type: 'pie-chart',
          time: Date.now(),
          haveSpaceIDs: {'Stored': this.getAllSpace.denode_usedIDs, 'Available': this.getAllSpace.denode_freeIDs}
        }
      }
    },
    getBalanceCards () {
      return [
        {
          title: 'Total proofs count:',
          subtitle: this.getTotalInfoEarnings.proofs_count,
          dates: [this.getLastMonthEarnings.proofs_count || 0, this.getLastWeekEarnings.proofs_count || 0, this.getLastDayEarnings.proofs_count || 0]
        },
        {
          title: 'Failed proofs count:',
          subtitle: this.getTotalInfoEarnings.failed_count,
          dates: [this.getLastMonthEarnings.failed_count || 0, this.getLastWeekEarnings.failed_count || 0, this.getLastDayEarnings.failed_count || 0],
          failed: true
        },
        {
          title: 'TBY mined:',
          subtitle: this.convertBalance(this.getTotalInfoEarnings?.tby_mined), icon: 'Icon', unit: 'TBY',
          dates: [
            this.convertBalance(this.getLastMonthEarnings.tby_mined || 0),
            this.convertBalance(this.getLastWeekEarnings.tby_mined || 0),
            this.convertBalance(this.getLastDayEarnings.tby_mined || 0)
          ]
        },
        {
          title: 'TBY rewarded:',
          subtitle: this.convertBalance(this.getTotalInfoEarnings?.system_reward_tby ), icon: 'Icon', unit: 'TBY',
          dates: [
            this.convertBalance(this.getLastMonthEarnings.system_reward_tby || 0),
            this.convertBalance(this.getLastWeekEarnings.system_reward_tby || 0),
            this.convertBalance(this.getLastDayEarnings.system_reward_tby || 0)
          ]
        },
        {
          title: 'Network gas token used:',
          subtitle: this.convertBalance(this.getTotalInfoEarnings?.fees), icon: 'Icon', unit: 'MATIC',
          dates: [
            this.convertBalance(this.getLastMonthEarnings.fees || 0),
            this.convertBalance(this.getLastWeekEarnings.fees || 0),
            this.convertBalance(this.getLastDayEarnings.fees || 0)
          ]
        }
      ]
    },
    getTotalInfoEarnings () {
      return this.allNodesEarnings.total_info
    },
    getLastMonthEarnings () {
      return this.allNodesEarnings.last_month
    },
    getLastWeekEarnings () {
      return this.allNodesEarnings.last_week
    },
    getLastDayEarnings () {
      return this.allNodesEarnings.last_day
    },
    getTotalOnlineNodes () {
      return this.nodes.filter(x =>x.online).length
    },
    getAllSpace () {
      const obj = { denode_free: 0, denode_shared: 0, denode_used: 0, denode_freeIDs: [], denode_usedIDs: [], real_free: 0, real_size: 0, real_used: 0, real_freeIDs: [], real_usedIDs: [], }
      this.nodes.forEach(el => {
        const space = el.resources?.space
        if (space?.denode_free) {
          obj.denode_free += space.denode_free
          obj.denode_freeIDs.push(el.id)
        }
        if (space?.denode_shared) obj.denode_shared += space.denode_shared
        if (space?.denode_used) {
          obj.denode_used += space.denode_used
          obj.denode_usedIDs.push(el.id)
        }
        if (space?.real_free) {
          obj.real_free += space.real_free
          obj.real_freeIDs.push(el.id)
        }
        if (space?.real_size) obj.real_size += space.real_size
        if (space?.real_used) {
          obj.real_used += space.real_used
          obj.real_usedIDs.push(el.id)
        }
      })
      return obj
    },
    getDataForChart () {
      return [this.getAllSpace.real_free, this.getAllSpace.real_used]
    },
    getDeNodeDataForChart () {
      return [this.getAllSpace.denode_free, this.getAllSpace.denode_used]
    },
    totalProofsCount () {
      return this.nodeProofs.length - 1
    },
    totalProofsSuccess () {
      return this.nodeProofs.filter(x => !x.failed).length - 1
    },
    totalProofsFailed () {
      return this.nodeProofs.filter(x => x.failed).length - 1
    },
    getBalanceTBY () {
      const allBalance = this.nodes.reduce((a, b) => a + b.balances[TOKEN_TBY], 0)
      return this.convertBalance(allBalance)
    },
    getBalanceTBYMined () {
      const allBalance = this.nodes.reduce((a, b) => a + b.balances[TOKEN_MINED_TBY], 0)
      return this.convertBalance(allBalance)
    },
    getBalanceMatic () {
      const allBalance = this.nodes.reduce((a, b) => a + b.balances[TOKEN_GAS], 0)
      return this.convertBalance(allBalance)
    }
  },
  async mounted () {
    this.getNodes()
    this.getNodesProofs()
    this.getNodesEarnings()
    this.storageDetails = await this.getStorageDetails()
  },
  methods: {
    ...mapActions(['getNodes', 'getNodesProofs', 'getNodesEarnings', 'getStorageDetails']),
    setActiveTab (tab) {
      this.activeTab = tab
    } 
  }
})
</script>
<style lang="scss" scoped>
.nodes-page{
  padding: 56px 150px 288px 150px;
  @media (min-width:1920px) {
    padding: 56px 200px 288px 200px;
  }
  @media (max-width:1600px) {
    padding: 56px 50px 288px 50px;
  }
}
.nodes-info {
  &__wrapper {
    display: flex;
  }
  $cols: ('col1': 17.4%, 'col2': 19.4%, 'col3': 31.6%, 'col4': 31.6%);
  @each $className, $value in $cols {
    &__#{ $className } {
      @include flexPercent($value);
      @if ($className == 'col2') {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  &__pie-chart {
    width: 200px;
    height: 200px;
  }
  &__link {
    @extend %fontMedium;
    color: $colorBlue;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  &__icon-link {
    margin-left: 4px;
  }
}
.proofs-and-reward {
  &__wrapper {
    display: flex;
  }
}
</style>