<template>
  <div class="pie-chart">
    <canvas width="200" height="200" :id="id" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { formatBytes } from '@/scripts/control'
export default {
  name: 'PieChart',
  props: ['data', 'id', 'labels', 'type', 'nodesHaveSpace'],
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.renderChart()
    console.log(this.id)
  },
  methods: {
    labelToolTip (tooltipItems) {
      return ' ' + tooltipItems.label + ': ' + formatBytes(tooltipItems.parsed)
    },
    titleToolTip (tooltipItems) {
      if (!this.nodesHaveSpace) return null
      const stringIDs = this.nodesHaveSpace[tooltipItems[0].label].map((item, index) => (index + 1) % 8 === 0 ? item + '\n' : item + ', ').join("").slice(0, -2);
      return  'Node IDs: ' + stringIDs
    },
    async renderChart () {
      let piaChartName = document.getElementById(this.id)
      const data = {
        labels: this.labels,
        type: this.type ? this.type : 'pie',
        datasets: [{
          data: this.data,
          backgroundColor: [
          '#32A143',
          '#FFD600',
          '#ED4848'
          ],
          hoverOffset: 4
        }]
      }
      this.chart = new Chart(piaChartName, {
        type: this.type ? this.type : 'pie',
        data: data,
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: this.labelToolTip,
                title: this.titleToolTip
              }
            }
          }
        }
      })
    }
  }
}
</script>
