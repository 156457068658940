<template>
  <div class="node-storage-info">
    <div class="node-storage-info__wrapper">
      <InfoCard
        v-for="(item, key, index) in getCardInfo"
        :key="index"
        :class="['node-storage-info__' + key, { 'node-storage-info__wrong-size-card': key === 'col2' && this.isWrongSize }]"
        :wrapperClass="item.wrapperClass"
        :content="item.card"
      >
        <template
          #chart
        >
          <PieChart
            v-if="item.type === 'pie-chart'"
            :key="key + item.time"
            class="node-info__pie-chart"
            :id="key + item.time"
            type="doughnut"
            :labels="['Available', 'Stored']"
            :data="item.data"
            :nodes-have-space="nodesHaveSpace"
          />
          <div
            v-if="item.type === 'single-chart'"
            :key="key + item.time"
            class="single-bar-chart"
          >
            <span
              v-for="(chart, index) in item.data"
              :key="index"
              class="single-bar-chart__col"
              :style="[{ height: chart + '%'}]"
            >
            </span>
          </div>
        </template>
      </InfoCard>
      <div class="node-storage-info__col4">
        <InfoCardBalance
          v-for="(card, index) in getCardCPU"
          :key="index"
          :content="card"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { decimalAdjust } from '@/scripts/control'
import { mapGetters } from 'vuex'
import PieChart from '@/components/charts/PieChart.vue'
import InfoCard from './NodeInfoCard.vue'
import InfoCardBalance from './NodeInfoCardBalance.vue'
export default {
  name: 'NodeStorageInfo',
  components: { InfoCard, InfoCardBalance, PieChart },
  computed: {
    ...mapGetters(['getStorageInfo']),
    getInfoCPU () {
      return this.getStorageInfo?.cpu
    },
    getInfoRAM () {
      console.log(this.getStorageInfo)
      return this.getStorageInfo?.ram
    },
    getInfoSpace () {
      return this.getStorageInfo?.space
    },
    getCardCPU () {
      return [
        { title: 'Node CPU usage:', subtitle: decimalAdjust(this.getInfoCPU?.proc_percent || 0, 'round') + '%' },
        { title: 'Total CPU usage:', subtitle: decimalAdjust(this.getInfoCPU?.total_percent || 0, 'round') + '%' },
      ]
    },
    getDataForChart () {
      return [this.getInfoSpace?.real_free || 0, this.getInfoSpace?.real_used || 0]
    },
    getDeNodeDataForChart () {
      console.log(this.getInfoSpace?.denode_shared)
      console.log(this.getInfoSpace?.real_size)
      return this.getInfoSpace?.denode_shared > this.getInfoSpace?.real_size ? [0, 0, 1] : [this.getInfoSpace?.denode_free || 0, this.getInfoSpace?.denode_used || 0]
    },
    getSingleBarChartData  () {
      const total = this.getInfoRAM?.total
      const nodeUsed = this.getInfoRAM?.node_used
      const used = this.getInfoRAM?.used
      const obj = {}
      if (total) obj.totalPercent = 100
      if (used && total) obj.usedPercent = (used * 100) / total
      if (nodeUsed && total) {
        obj.nodeUsedPercent = (nodeUsed * 100) / total
      }
      return obj
    },
    isWrongSize() { return this.getInfoSpace?.denode_shared > this.getInfoSpace?.real_size },
    getCardInfo () {
      return {
        col1: {
          card: [
            { title: 'Real size:', subtitle: this.formatBytesArr(this.getInfoSpace?.real_size || 0).count, unit: this.formatBytesArr(this.getInfoSpace?.real_size || 0).unit, class: 'fs-24' },
            { title: 'Real stored:', subtitle: this.formatBytesArr(this.getInfoSpace?.real_used || 0).count, unit: this.formatBytesArr(this.getInfoSpace?.real_used || 0).unit, class: 'fs-24' },
            { title: 'Real available:', subtitle: this.formatBytesArr(this.getInfoSpace?.real_free || 0).count, unit: this.formatBytesArr(this.getInfoSpace?.real_free || 0).unit, class: 'fs-24' }
          ],
          type: 'pie-chart',
          wrapperClass: 'flex-center',
          data: this.getDataForChart,
          time: Date.now()
        },
        col2: {
          card: [
            { title: 'Shared:', subtitle: this.isWrongSize ? this.formatBytesArr(this.getInfoSpace?.denode_shared || 0).count : this.formatBytesArr(this.getInfoSpace?.denode_shared || 0).count, unit: this.formatBytesArr(this.getInfoSpace?.denode_shared || 0).unit, class: 'fs-24' },
            { title: 'Stored:', subtitle: this.formatBytesArr(this.getInfoSpace?.denode_used || 0).count, unit: this.formatBytesArr(this.getInfoSpace?.denode_used || 0).unit, class: 'fs-24' },
            { title: 'Available:', subtitle: this.isWrongSize ? this.formatBytesArr(this.getInfoSpace?.denode_free || 0).count : this.formatBytesArr(this.getInfoSpace?.denode_free || 0).count, unit: this.formatBytesArr(this.getInfoSpace?.denode_free || 0).unit, class: 'fs-24' }
          ],
          type: 'pie-chart',
          wrapperClass: 'flex-center',
          data: this.getDeNodeDataForChart,
          time: Date.now(),
        },
        col3: {
          card: [
            { title: 'RAM capacity:', subtitle: this.formatBytesArr(this.getInfoRAM?.total || 0).count, unit: this.formatBytesArr(this.getInfoRAM?.total || 0).unit, class: 'fs-24' },
            { title: 'Used RAM:', subtitle: this.formatBytesArr(this.getInfoRAM?.used || 0).count, unit: this.formatBytesArr(this.getInfoRAM?.used || 0).unit, class: 'fs-24' },
            { title: 'Node RAM usage:', subtitle: this.formatBytesArr(this.getInfoRAM?.node_used || 0).count, unit: this.formatBytesArr(this.getInfoRAM?.node_used || 0).unit, class: 'fs-24' }
          ],
          type: 'single-chart',
          wrapperClass: 'flex-center',
          data: this.getSingleBarChartData,
          time: Date.now()
        }
      }
    }
  },
  methods: {
    formatBytesArr (bytes, decimals = 2) {
      if ([0, null, undefined].includes(bytes)) return { count: '0', unit: 'Bytes' }
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      const i = Math.floor(Math.abs(Math.log(bytes)) / Math.log(k))
      return { count: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i] }
    }
  }
}
</script>
<style lang="scss" scoped>
.node-storage-info {
	&__wrapper {
    display: flex;
	}
  $cols: ('col1': 30.5%, 'col2': 30.5%, 'col3': 22%, 'col4': 17%);
  @each $className, $value in $cols {
    &__#{ $className } {
      @include flexPercent($value);
      @if ($className == 'col4') {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &__wrong-size-card {
    color: $colorRed;
  }
}
.single-bar-chart {
  position: relative;
  min-height: 266px;
  height: 100%;
  width: 80px;
  // margin-left: auto;
  &__col {
    @include setPosition(absolute, auto, 0, 0, auto);
    display: flex;
    height: 0%;
    width: 100%;
    $chartCol:
      1 (8px 8px 0 0) 100% $colorGreen,
      2 (8px 0 0 0) 85% $colorYellow,
      3 (8px 0 0 0) 70% $colorOrange;
    @each $index, $bdRadius, $width, $bgColor in $chartCol {
      &:nth-child(#{$index}) {
        border-radius: $bdRadius;
        width: $width;
        background-color: $bgColor;
      }
    }
  }
}
</style>