function secondsToHms (time) {
  var hour = Math.floor(time / 3600)
  var minute = Math.floor(time % 3600 / 60)
  var second = Math.floor(time % 3600 % 60)
  var hDisplay = hour > 0 ? hour + (hour == 1 ? " h, " : " h, ") : ""
  var mDisplay = minute > 0 ? minute + (minute == 1 ? " m, " : " m, ") : ""
  var sDisplay = second > 0 ? second + (second == 1 ? " s" : " s") : ""
  return hDisplay + mDisplay + sDisplay
}

function formatBytes (bytes, decimals = 2) {
  if ([0, null, undefined].includes(bytes)) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  const i = Math.floor(Math.abs(Math.log(bytes)) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function decimalAdjust (value, type, exp = -3) {
  value = Number(value)
  if (exp === 0) {
    return Math[type](value)
  }
  const [magnitude, exponent = 0] = value.toString().split('e')
  const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`)
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e')
  return Number(`${newMagnitude}e${+newExponent + exp}`)
}

export {
  formatBytes,
  secondsToHms,
  decimalAdjust
}