<template>
  <div class="denet-desk">
    <div class="denet-desk__wrapper">
      <div class="denet-desk__input-date">
        <input
          type="date"
          ref="FirstInput"
          :value="beginTime"
          @keyup="FirstDay"
        > -
        <input
          type="date"
          ref="SecondInput"
          :value="endTime"
          @keyup="SecondDay"
        >
        <button
          class="denet-desk__send-date"
          @click="sendDate"
        >Send</button>
      </div>
        <BarChart
          id="clicksBarChart"
          title="button clicks in day"
          :labels= 'getDays'
          :data='getButtonClicks'
          :background-color='["green"]'
          :border-color="'rgba(255, 255, 255, 1)'"
          :widthContent="'950px'"
          class="denet-desk__bar-chart"
        />
        <BarChart
          id="dappsBarChart"
          title="dapp clicks in day"
          :labels= 'getDays'
          :data='getDappClicks'
          :background-color='["blue"]'
          :border-color="'rgba(255, 255, 255, 1)'"
          :widthContent="'950px'"
          class="denet-desk__bar-chart"
        />
      <table class="denet-desk__table denet-desk__border-table">
        <div>
          <thead>
            <tr>
              <th class="denet-desk__first-tb-header denet-desk__subtitle">Date</th>
              <th class="denet-desk__second-tb-header denet-desk__subtitle">Address spent at the desk</th>
              <th class="denet-desk__third-tb-header denet-desk__subtitle">Time</th>
              <th class="denet-desk__third-tb-header denet-desk__subtitle">Total Time</th>
            </tr>
          </thead>
        </div>
        <div class="denet-desk__scroll">
          <tbody>
            <tr
              v-for="item in deskStatsInfo"
              :key="item"
            >
              <td class="denet-desk__border-table denet-desk__first-tb-header">{{ item.Date.slice(0, 10) }}</td>
              <td>
                <div
                  v-for="index in Object.assign({}, Object.keys(item.TimeInDesk))"
                  :key="index"
                >
                  <div class="denet-desk__border-table">{{index}}</div>
                </div>
              </td>
              <td>
                <div
                  v-for="index in Object.assign({}, Object.values(item.TimeInDesk))"
                  :key="index"
                >
                  <div class="denet-desk__third-tb-header denet-desk__border-table">{{ secondsToHms(index)}}</div>
                </div>
              </td>
              <td class="denet-desk__third-tb-header denet-desk__border-table">{{ secondsToHms(Object.values(item.TimeInDesk).reduce((a, b) => a + b, 0)) }}</td>
            </tr>
          </tbody>
        </div>
      </table>
      <table class="denet-desk__table denet-desk__border-table">
        <div>
          <thead>
            <tr>
              <th class="denet-desk__first-tb-header denet-desk__subtitle">Date</th>
              <th class="denet-desk__second-tb-header denet-desk__subtitle">Name dapps</th>
              <th class="denet-desk__third-tb-header denet-desk__subtitle">Clicks</th>
              <th class="denet-desk__third-tb-header denet-desk__subtitle">Total Clicks</th>
            </tr>
          </thead>
        </div>
        <div class="denet-desk__scroll">
          <tbody>
            <tr
              v-for="item in deskStatsInfo"
              :key="item"
              class="denet-desk__border-table"
            >
              <td class="denet-desk__border-table denet-desk__first-tb-header">{{ item.Date.slice(0, 10) }}</td>
              <td>
                <div
                  v-for="index in Object.assign({}, Object.keys(item.Dapps.Activity))"
                  :key="index"
                >
                  <div class="denet-desk__second-tb-header denet-desk__border-table">{{index}}</div>
                </div>
              </td>
              <td>
                <div
                  v-for="index in Object.assign({}, Object.values(item.Dapps.Activity))"
                  :key="index"
                >
                  <div class="denet-desk__third-tb-header denet-desk__border-table">{{ index }}</div>
                </div>
              </td>
              <td  class="denet-desk__border-table denet-desk__third-tb-header">{{ Object.values(item.Dapps.Activity).reduce((a, b) => a + b, 0) }}</td>
            </tr>
          </tbody>
        </div>
      </table>
      <table class="denet-desk__table denet-desk__border-table">
        <div>
          <thead>
            <tr>
              <th class="denet-desk__first-tb-header denet-desk__subtitle">Date</th>
              <th class="denet-desk__second-tb-header denet-desk__subtitle">Name</th>
              <th class="denet-desk__third-tb-header denet-desk__subtitle">Clicks</th>
              <th class="denet-desk__third-tb-header denet-desk__subtitle">Total Clicks</th>
            </tr>
          </thead>
        </div>
        <div class="denet-desk__scroll">
          <tbody>
            <tr
              v-for="item in deskStatsInfo"
              :key="item"
              class="denet-desk__border-table"
            >
              <td class="denet-desk__border-table denet-desk__first-tb-header">{{ item.Date.slice(0, 10) }}</td>
              <td>
                <div
                  v-for="index in Object.assign({}, Object.keys(item.Buttons.Activity))"
                  :key="index"
                >
                  <div class="denet-desk__second-tb-header denet-desk__border-table">{{index}}</div>
                </div>
              </td>
              <td>
                <div
                  v-for="index in Object.assign({}, Object.values(item.Buttons.Activity))"
                  :key="index"
                >
                  <div class="denet-desk__third-tb-header denet-desk__border-table">{{ index }}</div>
                </div>
              </td>
              <td  class="denet-desk__border-table denet-desk__third-tb-header">{{ Object.values(item.Buttons.Activity).reduce((a, b) => a + b, 0) }}</td>
            </tr>
          </tbody>
        </div>
      </table>
    </div>
    <div style="position: absolute; top:5%; right:5%;">
      <a @click="$router.go(-1)">
        <button>back</button>
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { secondsToHms } from '@/scripts/control'
import BarChart from "@/components/charts/BarChart.vue"
import { Mixin } from "@/mixins/getDate.js"
export default({
  components:{
    BarChart
  },
  mixins:[Mixin],
  computed: {
    ...mapState(['clickValue', 'deskStatsInfo', 'endTime', 'beginTime']),
    getDays () {
      var days = []
      for (let i=0; i<this.deskStatsInfo.length; i++) {
        days.push(this.deskStatsInfo[i].Date.slice(0, 10))
      }
      return days
    },
    getDappClicks () {
      var clicks = []
      for (let i=0; i<this.deskStatsInfo.length; i++) {
        clicks.push(Object.values(this.deskStatsInfo[i].Dapps.Activity).reduce((a, b) => a + b, 0))
      }
      return clicks
    },
    getButtonClicks () {
      var clicks = []
      for (let i=0; i<this.deskStatsInfo.length; i++) {
        clicks.push(Object.values(this.deskStatsInfo[i].Buttons.Activity).reduce((a, b) => a + b, 0))
      }
      return clicks
    }
  },
  mounted (){
    this.$store.commit('stateUpdater', { name: 'endTime', value: this.getToday })
    this.$store.commit('stateUpdater', { name: 'beginTime', value: this.sevenDaysAgo })
    this.$store.dispatch('deskInfo')
  },
  methods: {
    secondsToHms
  }
})
</script>

<style lang="scss" scoped>
.denet-desk{
  display:flex;
  justify-content: center;
  padding: 0 30px;
  &__input-date{
    padding:20px 0 10px 10px;
    background: #ffffff;
    position: fixed;
    width: max-content;
    border-bottom: solid 1px;
    border-left: solid 1px;
  }
  &__chart{
    display:flex;
    justify-content: space-around;
    padding: 20px 0;
    align-items: center;
  }
  &__send-date{
    padding: 2px 30px;
    margin: 0 10px;
  }
  &__table{
    margin: 50px 0;
    text-align: center;
  }
  &__info{
    font-size: 18px;
    font-weight: 500;
  }
  &__scroll{
    max-height: 350px;
    overflow: auto;
    font-size: 18px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__border-table{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px 10px;
  }
  &__subtitle{
    font-size: 22px;
    font-weight: 600;
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px 10px;
  }
  &__bar-chart{
    background: #ffffff;
    // box-shadow: 0 15px 59px rgb(0 0 0 / 13%);
    border-radius: 10px;
    padding: 20px;
    margin: 70px 40px;
  }
  &__first-tb-header{
    min-width:100px;
  }
  &__second-tb-header{
    min-width:440px;
  }
  &__third-tb-header{
    min-width:175px;
  }
}
</style>