<template>
	<router-view></router-view>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapState(['login', 'user'])
  },
  mounted () {
    const $this = this
    if (this.login === false) {
      this.$router.push({ path: '/login'})
    }
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum.on('accountsChanged', async (account) => {
        console.log(account)
        if (account[0] === $this.user.address) {
          await $this.userSession()
          $this.setUserLogin(true)
        } else if (account.length === 0) {
          $this.logout()
          $this.$router.push({ path: '/login'})
        } else {
          $this.setUserLogin(false)
        }
        // $this.logout()
      })
    }
  },
  methods: {
    ...mapActions({
      logout: 'logout',
      userSession: 'userSession',
      setUserLogin: 'setUserLogin'
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/fonts.scss';
@import '@/assets/css/normalize.css';
body {
  color: #232323;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  margin: 0;
  @media (min-width: 1921px) {
    font-size: .8vw;
  }
}
#app {
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 calc(20px - (100vw - 100%)) 0 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}
</style>
