export const Mixin ={
  computed:{
    getToday () {
      const today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0')
      var yyyy = today.getFullYear()
      var day = yyyy + '-' + mm + '-' + dd
      return day
    },
    sevenDaysAgo () {
      const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      var dd = String(sevenDaysAgo.getDate()).padStart(2, '0')
      var mm = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0')
      var yyyy = sevenDaysAgo.getFullYear()
      var day = yyyy + '-' + mm + '-' + dd
      return day
    },
  },
  methods: {
    FirstDay () {
      this.$store.commit('stateUpdater', { name: 'beginTime', value: this.$refs.FirstInput.value })
    },
    SecondDay () {
      this.$store.commit('stateUpdater', { name: 'endTime', value: this.$refs.SecondInput.value })
    },
    sendDate () {
      this.$store.commit('stateUpdater', { name: 'beginTime', value: this.$refs.FirstInput.value })
      this.$store.commit('stateUpdater', { name: 'endTime', value: this.$refs.SecondInput.value })
      if (this.$route.name === 'DenetStorage') {
        this.$store.dispatch('storageInfo')
      } else if (this.$route.name === 'NFTStorage') {
        this.$store.dispatch('nftInfo')
      } else if (this.$route.name === 'AnalyticsNode') {
        this.$store.dispatch('nodeInfo')
      } else {
        this.$store.dispatch('deskInfo')
      }
      this.$store.commit('stateUpdater', { name: 'clickValue', value: true })
    }
  }
}
