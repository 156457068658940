<template>
	<transition name="component-fade" mode="out-in">
		<div class="main-page">
			<div v-for="item in listProjects" :key="item">
				<div v-if="show && item.show" @click="viewMetrics(item.url)">
					<div class="main-page__title">
						<span>{{ item.title }}</span>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
// index page with menu and route: "/"
import { mapState } from 'vuex';
export default {
	name: 'mainPage',
	components: {},
	data() {
		return {
			show: false,
			listProjects: [ // show - will the button be shown
				{ title: 'Defi Skills', url: '/skills', show: true },
				{ title: 'Denet Storage', url: '/storage', show: false },
				{ title: 'Denet Desk', url: '/desk', show: false },
				{ title: 'Denet Asistant', url: '/tg-bot', show: false },
				{ title: 'NFT Storage', url: '/nft', show: false },
				{ title: 'Node', url: '/node', show: true },
				{ title: 'Download', url: '/download', show: true },
			],
		};
	},
	computed: {
		...mapState(['clickValue', 'login']),
	},
	mounted() {
		this.show = true;
	},
	methods: {
		viewMetrics(url) {
			if (this.login) {
				this.$router.push(url);
				this.$store.commit('stateUpdater', { name: 'clickValue', value: false });
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.main-page {
	flex: 0 0 16%;
	max-width: 16%;
	&__title {
		cursor: pointer;
		background: rgb(95, 93, 93);
		margin: 10px;
		padding: 10px 20px;
		max-width: 100%;
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		&:hover {
			background: rgb(45, 41, 41);
		}
		@media (min-width: 1921px) {
			margin: 0.5vw;
			padding: 0.5vw 1vw;
			font-size: 0.9vw;
		}
	}
}
</style>
