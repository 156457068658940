export const IconTBY = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <ellipse
        cx="12"
        cy="12.9434"
        rx="12"
        ry="12"
        fill="#232323"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5886 9.91143C19.5323 9.77042 19.3696 9.70731 19.2305 9.76837C19.0915 9.82942 19.0288 9.9915 19.0848 10.1326C19.7358 11.7736 19.798 13.5929 19.2553 15.2788C18.6845 17.052 17.484 18.5548 15.8806 19.5033C14.2773 20.4518 12.3821 20.7802 10.5531 20.4265C8.72412 20.0729 7.08797 19.0617 5.95376 17.5839C4.81955 16.1061 4.26582 14.2641 4.39717 12.4058C4.52853 10.5476 5.33587 8.80182 6.66669 7.49828C7.99751 6.19474 9.75964 5.42372 11.6202 5.33088C13.373 5.24341 15.0988 5.76354 16.5085 6.79778C16.4295 6.94634 16.3848 7.11586 16.3848 7.29582C16.3848 7.88289 16.8607 8.35881 17.4478 8.35881C18.0349 8.35881 18.5108 7.88289 18.5108 7.29582C18.5108 6.70874 18.0349 6.23282 17.4478 6.23282C17.2416 6.23282 17.0491 6.29153 16.8862 6.39315C15.3652 5.25853 13.4938 4.68678 11.5928 4.78165C9.598 4.88119 7.70873 5.70784 6.28189 7.10542C4.85506 8.50301 3.98946 10.3748 3.84863 12.3671C3.7078 14.3594 4.30148 16.3343 5.51753 17.9187C6.73357 19.5031 8.48777 20.5873 10.4487 20.9665C12.4097 21.3456 14.4416 20.9935 16.1606 19.9766C17.8796 18.9597 19.1667 17.3485 19.7787 15.4473C20.3626 13.6335 20.2935 11.6756 19.5886 9.91143ZM17.4478 7.81668C17.1601 7.81668 16.9269 7.58348 16.9269 7.29582C16.9269 7.00815 17.1601 6.77495 17.4478 6.77495C17.7355 6.77495 17.9687 7.00815 17.9687 7.29582C17.9687 7.58348 17.7355 7.81668 17.4478 7.81668Z"
        fill="#FFD600"
      />
      <path
        d="M7.90124 14.8787C7.90124 14.9121 7.9283 14.9391 7.96168 14.9391H8.38465C8.41803 14.9391 8.44509 14.9121 8.44509 14.8787V11.6321C8.44509 11.5987 8.47214 11.5717 8.50552 11.5717H9.70307C9.73644 11.5717 9.7635 11.5446 9.7635 11.5112V11.1542C9.7635 11.1208 9.73644 11.0938 9.70307 11.0938H6.64326C6.60988 11.0938 6.58282 11.1208 6.58282 11.1542V11.5112C6.58282 11.5446 6.60988 11.5717 6.64326 11.5717H7.8408C7.87418 11.5717 7.90124 11.5987 7.90124 11.6321V14.8787Z"
        fill="#FFD600"
      />
      <path
        d="M13.0989 12.9973C13.0478 12.9755 13.0402 12.8962 13.0854 12.864C13.3137 12.7015 13.4656 12.4395 13.4656 12.0881C13.4656 11.4673 12.9712 11.0938 12.0867 11.0938H10.4222C10.3889 11.0938 10.3618 11.1208 10.3618 11.1542V14.8787C10.3618 14.9121 10.3889 14.9391 10.4222 14.9391H12.1966C13.1689 14.9391 13.6688 14.5601 13.6688 13.9009C13.6688 13.4513 13.4477 13.1464 13.0989 12.9973ZM12.0373 11.5387C12.5976 11.5387 12.9162 11.7475 12.9162 12.154C12.9162 12.5605 12.5976 12.7692 12.0373 12.7692H10.9716C10.9382 12.7692 10.9111 12.7422 10.9111 12.7088V11.5992C10.9111 11.5658 10.9382 11.5387 10.9716 11.5387H12.0373ZM12.1746 14.4942H10.9716C10.9382 14.4942 10.9111 14.4671 10.9111 14.4337V13.2746C10.9111 13.2413 10.9382 13.2142 10.9716 13.2142H12.1746C12.7844 13.2142 13.114 13.412 13.114 13.8569C13.114 14.3019 12.7844 14.4942 12.1746 14.4942Z"
        fill="#FFD600"
      />
      <path
        d="M17.3826 11.1855C17.4071 11.1453 17.3781 11.0938 17.331 11.0938H16.9284C16.9073 11.0938 16.8878 11.1047 16.8768 11.1227L15.7102 13.036C15.6866 13.0747 15.6304 13.0746 15.6069 13.0359L14.4456 11.1228C14.4346 11.1048 14.4151 11.0938 14.3939 11.0938H13.9478C13.9006 11.0938 13.8716 11.1454 13.8962 11.1856L15.3585 13.5843C15.3643 13.5937 15.3673 13.6046 15.3673 13.6157V14.8787C15.3673 14.9121 15.3944 14.9391 15.4278 14.9391H15.8507C15.8841 14.9391 15.9112 14.9121 15.9112 14.8787V13.6266C15.9112 13.6156 15.9142 13.6047 15.9199 13.5953L17.3826 11.1855Z"
        fill="#FFD600"
      />
    </svg>
  )
}

export const IconDE = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="#FFD600"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7668 8.49687C20.7017 8.33396 20.5138 8.26105 20.3531 8.33159C20.1925 8.40212 20.12 8.58937 20.1847 8.75245C20.9369 10.6483 21.0087 12.7502 20.3817 14.6979C19.7223 16.7465 18.3353 18.4828 16.4829 19.5785C14.6306 20.6743 12.441 21.0538 10.3279 20.6452C8.21487 20.2366 6.32459 19.0683 5.01421 17.361C3.70383 15.6537 3.06409 13.5256 3.21585 11.3787C3.36761 9.23186 4.30035 7.21491 5.83787 5.70891C7.3754 4.20291 9.41123 3.31213 11.5608 3.20487C13.5845 3.10388 15.5771 3.70397 17.2052 4.89735C17.1135 5.06933 17.0616 5.26569 17.0616 5.47419C17.0616 6.15245 17.6114 6.70229 18.2897 6.70229C18.9679 6.70229 19.5178 6.15245 19.5178 5.47419C19.5178 4.79593 18.9679 4.24609 18.2897 4.24609C18.0521 4.24609 17.8302 4.31356 17.6423 4.43039C15.8855 3.1207 13.7244 2.46079 11.5291 2.57033C9.22447 2.68534 7.04176 3.64038 5.39331 5.25504C3.74485 6.8697 2.74481 9.03217 2.58211 11.3339C2.4194 13.6357 3.1053 15.9173 4.51022 17.7478C5.91514 19.5783 7.9418 20.8309 10.2073 21.269C12.4729 21.707 14.8204 21.3002 16.8064 20.1254C18.7924 18.9505 20.2794 17.089 20.9865 14.8925C21.6611 12.797 21.5812 10.535 20.7668 8.49687ZM18.2897 6.07596C17.9573 6.07596 17.6879 5.80653 17.6879 5.47419C17.6879 5.14184 17.9573 4.87242 18.2897 4.87242C18.622 4.87242 18.8914 5.14184 18.8914 5.47419C18.8914 5.80653 18.622 6.07596 18.2897 6.07596Z"
        fill="#232323"
      />
      <path
        d="M5.91406 14.9446C5.91406 15.0296 5.98295 15.0985 6.06792 15.0985H8.71913C10.7391 15.0985 12.124 13.8812 12.124 12.0112C12.124 10.1411 10.7391 8.92383 8.71913 8.92383H6.06792C5.98295 8.92383 5.91406 8.99271 5.91406 9.07768V14.9446ZM7.49691 13.9253C7.41194 13.9253 7.34306 13.8564 7.34306 13.7715V10.2509C7.34306 10.1659 7.41194 10.097 7.49691 10.097H8.64856C9.87467 10.097 10.6774 10.8292 10.6774 12.0112C10.6774 13.1932 9.87467 13.9253 8.64856 13.9253H7.49691Z"
        fill="#232323"
      />
      <path
        d="M14.7757 13.9518C14.6907 13.9518 14.6219 13.8829 14.6219 13.7979V12.6766C14.6219 12.5917 14.6907 12.5228 14.7757 12.5228H17.3348C17.4198 12.5228 17.4887 12.4539 17.4887 12.3689V11.5652C17.4887 11.4802 17.4198 11.4113 17.3348 11.4113H14.7757C14.6907 11.4113 14.6219 11.3425 14.6219 11.2575V10.2244C14.6219 10.1394 14.6907 10.0706 14.7757 10.0706H17.7141C17.7991 10.0706 17.868 10.0017 17.868 9.9167V9.07768C17.868 8.99271 17.7991 8.92383 17.7141 8.92383H13.3555C13.2706 8.92383 13.2017 8.99271 13.2017 9.07768V14.9446C13.2017 15.0296 13.2706 15.0985 13.3555 15.0985H17.8288C17.9138 15.0985 17.9826 15.0296 17.9826 14.9446V14.1056C17.9826 14.0207 17.9138 13.9518 17.8288 13.9518H14.7757Z"
        fill="#232323"
      />
    </svg>
  )
}

export const IconMATIC = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.9953 0C18.6257 0 24 5.37424 24 12.0047C24 18.6351 18.6257 24.0046 12 24.0046C5.36954 24.0046 3.11002e-06 18.6304 3.11002e-06 12.0047C-0.00471944 5.37424 5.36954 0 11.9953 0Z"
        fill="#8247E5"
      />
      <mask
        id="mask0_1454_2420"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="16"
        height="14"
      >
        <path
          d="M19.4075 5.48828H4.41797V18.6238H19.4075V5.48828Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1454_2420)">
        <path
          d="M15.8425 9.44252C15.568 9.28502 15.217 9.28502 14.9065 9.44252L12.7195 10.7385L11.2345 11.562L9.08799 12.8535C8.81349 13.011 8.46249 13.011 8.15199 12.8535L6.47349 11.832C6.19899 11.6745 6.00549 11.3595 6.00549 11.0085V9.04652C6.00549 8.73152 6.16299 8.42102 6.47349 8.22302L8.15199 7.24202C8.42649 7.08452 8.77749 7.08452 9.08799 7.24202L10.7665 8.26352C11.041 8.42102 11.2345 8.73602 11.2345 9.08702V10.383L12.7195 9.51902V8.18702C12.7195 7.87202 12.562 7.56152 12.2515 7.36352L9.12849 5.51852C8.85399 5.36102 8.50299 5.36102 8.19249 5.51852L4.99299 7.40402C4.68249 7.56152 4.52499 7.87652 4.52499 8.18702V11.8725C4.52499 12.1875 4.68249 12.498 4.99299 12.696L8.15649 14.541C8.43099 14.6985 8.78199 14.6985 9.09249 14.541L11.239 13.2855L12.724 12.4215L14.8705 11.166C15.145 11.0085 15.496 11.0085 15.8065 11.166L17.485 12.147C17.7595 12.3045 17.953 12.6195 17.953 12.9705V14.9325C17.953 15.2475 17.7955 15.558 17.485 15.756L15.847 16.737C15.5725 16.8945 15.2215 16.8945 14.911 16.737L13.228 15.756C12.9535 15.5985 12.76 15.2835 12.76 14.9325V13.677L11.275 14.541V15.837C11.275 16.152 11.4325 16.4625 11.743 16.6605L14.9065 18.5055C15.181 18.663 15.532 18.663 15.8425 18.5055L19.006 16.6605C19.2805 16.503 19.474 16.188 19.474 15.837V12.111C19.474 11.796 19.3165 11.4855 19.006 11.2875L15.8425 9.44252Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
