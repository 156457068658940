<template>
  <!-- <div class="main-header">
    <div class="main-header__links">
      <button
        @click="$router.go(-1)"
        class="main-header__back"
      >
        <IconBack />
      </button>
      <button
        class="main-header__main-page"
        @click="$router.push('/')"
      >
        analytics.denetwork
      </button>
      <div class="main-header__icon-arrow">
        <IconArrow />
      </div>
      <button @click="$router.push('/node')">Nodes</button>
      <span>Node {{ $router.name }}</span>
    </div>
      <button class="main-header__update-btn">
        <LogoDeNet class="main-header__logo" />
        Update nodes
      </button>
  </div> -->
  <div class="breadcrumbs">
    <button
      class="breadcrumbs__back"
      @click="$router.go(-1)"
    >
      <IconBack class="breadcrumbs__back-icon"/>
    </button>
    <div class="breadcrumbs__links">
      <span
        class="breadcrumbs__item"
        @click="goToMainPage()"
      >analytics.denetwork</span>
      <!-- v-for="(item, index) in items" -->
      <!-- :key="index" -->
      <div
        class="breadcrumbs__item"
      >
        <IconArrow class="breadcrumbs__arrow-icon"/>
        <span>{{ $route.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { IconBack, IconArrow } from '@/components/icons/index.js'
export default({
  name: 'TheBreadcrumbs',
  components: { IconBack, IconArrow },
  data () {
    return {
      items: ['nodes', 'node', 'true']
    }
  },
  methods: {
    goToMainPage () {
      this.$router.push({ path: '/' })
    }
  }
})
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border: 1px solid $colorYellow;
    border-radius: 12px;
  }
  &__icon {
    height: 13px;
    width: auto;
  }
  &__links {
    display: flex;
  }
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $colorGreySecondary2;
  }
  &__arrow-icon {
    display: block;
    margin: 0 12px;
  }
}
// .main-header{
//   // padding-top: 56px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   // @media (min-width:1920px) {
//   //   padding-top: 56px;
//   // }
//   // @media (max-width:1600px) {
//   //   padding-top: 50px 0;
//   // }
//   &__main-page{
//     cursor: pointer;
//   }
//   &__back{
//     padding: 10.4px 12px;
//     border-radius: 12px;
//     border: 1px solid #FFD600;
//     margin-right: 16px;
//   }
//   &__links{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   &__update-btn{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #FFD600;
//     padding: 8px 42px;
//     border-radius: 10px;
//     font-size: 16px;
//     font-weight: 400;
//   }
//   &__logo{
//     margin-right: 7px;
//   }
//   &__icon-arrow{
//     margin: 5px 12px 0 12px;
//   }
// }
</style>
