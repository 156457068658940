<template>
  <div class="node-info-tabs">
    <div class="node-info-tabs__wrapper">
      <div
        v-for="(tab, key, index) in tabs"
        :key="index"
        class="node-info-tabs__tab"
        :class="{ 'node-info-tabs_tab-active': index === activeTab }"
        @click="activeTab = index; $emit('setActiveTab', key)"
      >
        <span>{{ tab }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NodeInfoTabs',
  emits: ['setActiveTab'],
  props: {
    tabs: { type: Object, default () {} }
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>
<style lang="scss" scoped>
.node-info-tabs {
  padding: 12px;
  &__wrapper {
    display: flex;
    position: relative;
    width: max-content;
    &::after {
      content: '';
      z-index: -1;
      @include setPosition(absolute, auto, 0, 0, 0);
      width: 100%;
      height: 2px;
      background-color: $colorGreySecondary5;
  
    }
  }
  &__tab {
    cursor: pointer;
    border-bottom: 2px solid transparent;
    @extend %fontMedium;
    font-size: 24px;
    line-height: 30px;
    padding: 0 24px 8px;
    color: $colorGreySecondary3;
  }
  &_tab-active {
    color: $colorDark;
    border-color: $colorYellow;
  }
}
</style>
