<template>
  <div class="select-version">
    <button
      class="select-version__button"
    >
      <span clas="select-version__button-text">
        <span v-if="$route.name === 'DenetStorage'">Version {{ selectedVersion }}</span>
        <span v-else>Node {{ selectedVersion }}</span>
      <span class="select-version__icon" /></span>
    </button>
    <div
      class="select-version__select"
      :class="{'select-version_list-opened':isActive}"
    >
      <div class="selection">
        <div
          v-for="(index) in versions"
          :key="index"
        >
          <div
            class="selection__item"
            @click="selectFunc(index)"
          >
            <span>{{ index }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'CheckVersion',
  components: {},
  data () {
    return {
      isActive: false
    }
  },
  props: ['versions'],
  computed: {
    ...mapState(['selectedVersion', 'storageDownloadInfo']),
  },
  mounted () {
    const $this = this
    this.$nextTick(() => {
      document.body.addEventListener('click', function (e) {
        const arrayClass = ['table-metrics__select-version']
        if (!arrayClass.includes(e.target.classList.value)) {
          $this.isActive = false
        } else {
          $this.isActive = !$this.isActive
        }
      })
    })
  },
  methods: {
    selectFunc (index) {
      this.isActive = !this.isActive
      this.$store.commit('stateUpdater', { name: 'selectedVersion', value: index })
      this.$store.dispatch('storageInfo')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/SelectVersion'
</style>
